import React, { useEffect, useState } from "react";
import { images } from "../../../constants";
import "./Counters.scss";
import { getShared } from "../../../API/susfApi";
import { useTranslation } from "react-i18next";

import i18nnn from "./../../../utils/i18n";

export default function Counters() {
  const [counterData, setCounterData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getShared()
      //.then((response) => response.json())
      .then((data) => {
        setCounterData(data.data.sharedData);
      });
  }, []);

  return (
    <div className="counters-container">
      <div className="title-card">
        <div className="img">
          <img src={images.season} className="mt-2 " />
        </div>
        <div className="label">
          <p>
            {" "}
            <h1>{t("season")}</h1>
          </p>
          <p>{counterData.seassonsCount}</p>
        </div>
      </div>
      <div className="card-outer">
        <div className="card-inner">
          <div className="card-info">
            <p>{counterData.universityCount}</p>
            <p>{t("university")}</p>
          </div>
          <img src={images.uni} alt="" />
        </div>
      </div>
      <div className="card-outer">
        <div className="card-inner">
          <div className="card-info">
            <p>{counterData.competionCount}</p>
            <p>{t("competitions")}</p>
          </div>
          <img src={images.ranks} alt="" />
        </div>
      </div>
      <div className="card-outer">
        <div className="card-inner">
          <div className="card-info">
            <p>{counterData.internationalCompetionCount}</p>
            <p>{t("internationalComp")}</p>
          </div>
          <img src={images.trophies} alt="" />
        </div>
      </div>
      <div className="card-outer">
        <div className="card-inner">
          <div className="card-info">
            <p>{counterData.studentMaleCount}</p>
            <p>{t("theMale")}</p>
          </div>
          <img src={images.boys} alt="" />
        </div>
      </div>
      <div className="card-outer">
        <div className="card-inner">
          <div className="card-info">
            <p>{counterData.studentFemaleCount}</p>
            <p>{t("theFemale")}</p>
          </div>
          <img src={images.girls} alt="" />
        </div>
      </div>
    </div>
  );
}
