import React, { useEffect, useState } from "react";
import "./Support.scss";
import { images } from "../../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { postTicket } from "../../../API/susfApi";
//import { useParams } from "react-router-dom";

const Support = () => {

    const [loding, setLoading] = useState(false);

    const [msgValue, setMessage] = useState("");
    const [name, setName] = useState("");
    // const [about, setAbout] = useState("");
    const [email, setEmail] = useState("");
    const [nId, setNId] = useState("");
    const [mobile, setMobile] = useState("");
    const [description, setDescription] = useState("");


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const send = () => {
        setLoading(true);
        // Validation
        if (!name  || !description ) {
            //toast.current.show({ severity: 'warn', summary: 'خطأ', detail: 'جميع الحقول مطلوبة', life: 5000 });
            setMessage('يجب إدخال الإسم و الوصف');

            setLoading(false);
            return;
        }
        // recaptchaRef.current.execute()
        //     .then(ele => {
        //         if (ele != '') {
                    postTicket({ name: name, about: "about", email: email, nationalId: nId, mobile: mobile, description: description }).then(res => {
                        if (res && res.successful) {
                            setMessage('تم إرسال الطلب ,سنقوم بالتواصل معكم فى أقرب وقت');

                            // toast.current.show({ severity: 'success', summary: 'تم إرسال الطلب', detail: 'سنقوم بالتواصل معكم فى أقرب وقت', life: 5000 });
                            setName("");
                            // setAbout("");
                            setEmail("");
                            setNId("");
                            setMobile("");
                            setDescription("");
                        }
                        setLoading(false);
                    })
            //     }
            // });


    }
    const recaptchaRef = React.useRef();

    return (
        <>
            <div className="technical-support">

                <div className="content">




                    <div className="form">
                        {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LcFLtgcAAAAAGV-jIFXB39ivPwFMzMJdB2mtGoR"
                        /> */}
                        <img src={images.communicate} alt="faq" />
                        <h3>
                            تفاصيل الطلب
                        </h3>

                        {msgValue && (<>
                            <div className="alertmsg">
                                {msgValue}
                            </div>
                        </>)}
                        
                        <div className="fields">
                            <div className="row">
                                {/* <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>معلومات الطلب</label>
                                        <input value={about} onChange={(e) => setAbout(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div> */}
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>الإسم</label>
                                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>البريد الإلكتروني</label>
                                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>الهوية الوطنية</label>
                                        <input type="text" value={nId} onChange={(e) => setNId(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label>رقم الجوال</label>
                                        <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>الوصف</label>
                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" ></textarea>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <button disabled={loding} className="btn" onClick={() => send()}>إرسال الطلب</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Support;
