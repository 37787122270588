import React from "react";
import SliderNews from "./components/slider/SliderNews.jsx";
import "./News.scss";
import { useTranslation } from "react-i18next";

export default function News() {
  const { t } = useTranslation();

  return (
    <div className="news-container">
      <div className="news-inner-container">
        <div className="title">
          <h1>{t("latestNews")} </h1>
          <hr />
        </div>
        <div className="slider-container-ext">
          <SliderNews />
        </div>
      </div>
    </div>
  );
}
