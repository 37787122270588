// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./lang/ar";
import en from "./lang/en";

i18n.use(initReactI18next).init({
  // Configure your options here
  resources: {
    ar: ar,
    en: en,

    // Add more locales as needed
  },
  lng: localStorage.getItem("i18nextLng") || "ar",
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React handles this for us
  },
});

export default i18n;
