import React, { useEffect, useState } from "react";
import { getAllPublication, getFile } from "../../../API/susfApi";
import { images } from "../../../constants";
import "./Prints.scss";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Prints = () => {

  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  useEffect(() => {
    getAllPublication().then((result) => {
      setNews(result.data);
      
    });
  }, []);

  const viewDoc = (attachKey, attachName) => {
    getFile(attachKey, attachName, "publication").then((response) => {
      window.open(response.data?.url, "_self");
    });
  };

  return (
    <div className="prints-container">
      <div className="headingImg">
        <img className="headingIcon" src={images.headingIcon} alt="" />
        <div className="overlay"></div>
      </div>

      <div className="prints-list container">
        <div className="accordion accordion-flush" id="printsAccordion">
          {news.map((item, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapsePrint" + index}
                  aria-expanded="true"
                  aria-controls={"collapsePrint" + index}
                >
                  <div className="accordHeading">
                    <p>{i18n.language == "ar" ? item.name : item.nameEn}</p>
                  </div>
                </button>
              </h2>
              <div
                id={"collapsePrint" + index}
                className="accordion-collapse collapse show"
                data-bs-parent="#printsAccordion"
              >
                <div className="accordion-body">
                  <div className="row prints-row">
                    {item.publication.map((print, index2) => (
                      <>
                        <div
                          className="col-sm-12 col-md-12 col-lg-6"
                          index={index2}
                        >
                          <div className="print-box">
                            <div className="info">
                              <h3>{i18n.language == "ar" ? print.name : print.nameEn}</h3>
                              <button
                                className="btn"
                                onClick={() =>
                                  viewDoc(print.attachKey, print.attachName)
                                }
                              >
                                {t("viewMore")}
                              </button>
                            </div>
                            <div className="img">
                              <img src={print.image} alt="laws" />
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prints;
