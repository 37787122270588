import bag from "../../../Assets/bag.svg";
import flag from "../../../Assets/flagsvg.svg";
import sms from "../../../Assets/sms-tracking.svg";
import setting from "../../../Assets/setting.svg";
import arrow from "../../../Assets/status-up.svg";
import bawbat from "../../../Assets/bawabat.svg";
import "./Services.scss";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();

  return (
    <div className="services-container ">
      <div className="right">
        <h2 className="">{t("nav.otherServices.main")}</h2>
        <hr />
      </div>
      <div className="left">
       
        {/* <div
          className="card-body"
          onClick={() => window.open("https://nashtsport.com", "_blank")}
        >
          <img className=" " src={flag} />
          <p>{t("nav.otherServices.nashet")}</p>
        </div> */}
        
        <div
          className="card-body"
          onClick={() =>
            window.open("https://admin.susf.sa/login?lt=1", "_blank")
          }
        >
          <img className=" " src={setting} />
          <p>{t("nav.otherServices.ahlia")}</p>
        </div>
        <div
          className="card-body"
          onClick={() =>
            window.open("https://admin.susf.sa/login?lt=3", "_blank")
          }
        >
          <img className=" " src={arrow} />
          <p>{t("nav.otherServices.result")}</p>
        </div>
        <div
          className="card-body"
          onClick={() =>
            window.open("https://admin.susf.sa/login?lt=2", "_blank")
          }
        >
          <img className=" " src={bawbat} />
          <p>{t("nav.otherServices.competition")}</p>
        </div>
        {/* <div
          className="card-body"
          onClick={() => window.open("https://susftask.com", "_blank")}
        >
          <img className=" " src={bag} />
          <p>{t("nav.otherServices.tasks")}</p>
        </div> */}
        <div
          className="card-body"
          onClick={() =>
            window.open(
              "https://outlook.office.com/owa/?realm=susfweb.com",
              "_blank"
            )
          }
        >
          <img className=" " src={sms} />
          <p>{t("nav.otherServices.email")}</p>
        </div>
      </div>
    </div>
  );
}
