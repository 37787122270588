import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { format } from "date-fns";
import { arSA } from "date-fns/locale";
import Slider from "react-slick";
import { Outlet, useParams } from "react-router-dom";
import { getSessons, getSportData, getSportResult, getLatestMatchesResult, getCompetionResult } from "../../API/susfApi";
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import ModalVideo from "react-modal-video";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Competitions.scss";
import i18n from "i18next";

export default function Compettitions() {
  const { t } = useTranslation();
  const [latestMatchesResult, setMatchesResultData] = useState([]);
  const [latestMatchesResultAll, setMatchesResultDataAll] = useState([]);
  const [latestMatch, setMatchesData] = useState([]);
  const [mainSeassonData, setMainSeassonsData] = useState([]);
  const [allSeassonNameList, setAllSeassonNameList] = useState([]);
  const [currentSeassonName, setCurrentSeassonName] = useState("");
  const [currentSeassonId, setCurrentSeassonId] = useState("");
  const [currentSeassonCompet, setCurrentSeassonCompet] = useState([]);
  const [currentSeassonCompetAll, setCurrentSeassonCompetAll] = useState([]);
  const [toggleSports, setToggleSports] = useState(true);
  const [seassonTeamResult, setSeassonTeamResult] = useState([]);

  const [gatheringData, setGatheringData] = useState([]);
  const [selectedComp, setSelectedComp] = useState("");
  const [selectedCompType, setSelectedCompType] = useState("");
  const [selectedCompTypeName, setSelectedCompTypeName] = useState("");

  const [compUniverData, setCompUniverData] = useState([]);

  const [compIndivFlag, setCompIndivFlag] = useState(false);

  const [compTeamResult, setCompTeamResult] = useState([]);

  const [compIndivScore, setCompIndivScore] = useState([]);


  const [imagesDataActive, setImagesDataActive] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [vidiosDataActive, setVidiosDataActive] = useState(false);
  const [vidiosData, setVidiosData] = useState([]);
  const [newsDataActive, setNewsDataActive] = useState(false);
  const [newsData, setNewsData] = useState([]);

  const [isMatchDetails, setMatchDetails] = useState("");


  const [selectedCompTypeFilter, setSelectedCompTypeFilter] = useState("");
  const [selectedCompTypeNameFilter, setSelectedCompTypeNameFilter] = useState(t("theMale"));// useState("الطلاب");


  const [selectedCompFilter, setSelectedCompFilter] = useState("");
  const [selectedCompNameFilter, setSelectedCompNameFilter] = useState(t("competitions"));//useState("المسابقات");

  const [allOrderData, setAllOrderData] = useState([]);
  const [isNotDetails, setIsNotDetails] = useState(true);

  const scoresSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setMatchDetails("");

    window.location.pathname.includes("matchdetails")
      ? setToggleSports(false)
      : setToggleSports(true);

    getSessons().then((data) => {
      setMainSeassonsData(data.data);

      if (data.data && data.data.length > 0) {
        if (i18n.language == "ar") {
          setCurrentSeassonName(
            data.data.filter(function (el) {
              return el.isCurrent === true;
            })[0].name
          );
        } else {
          setCurrentSeassonName(
            data.data.filter(function (el) {
              return el.isCurrent === true;
            })[0].nameEn
          );
        }
        setCurrentSeassonId(data.data.filter(function (el) {
          return el.isCurrent === true;
        })[0]._id);

        const gatheringData = data.data.filter(function (el) {
          return el.isCurrent === true;
        })[0].gatheringPlaces;
        setGatheringData(
          gatheringData.slice(
            0,
            gatheringData.length > 7 ? 7 : gatheringData.length
          )
        );

        setAllSeassonNameList(
          data.data.map((x) => {
            return {
              id: x._id,
              name: x.name,
              nameEn: x.nameEn,
            };
          })
        );

        setCurrentSeassonCompet(
          data.data.filter(function (el) {
            return el.isCurrent === true;
          })[0].competions
        );

        setCurrentSeassonCompetAll(
          data.data.filter(function (el) {
            return el.isCurrent === true;
          })[0].competions
        );
      }
    });
  }, []);

  const navigate = useNavigate();
  const getCompData = (id, sportType, name, compTypeName, compType, image) => {
    // let navigate = useNavigate()
    //navigate("/competitions/coms");

    setSelectedCompFilter(id);
    setSelectedCompNameFilter(name + " - " + compTypeName);

    setSeassonTeamResult([]);
    setCompTeamResult([]);
    setMatchesResultData([]);
    setMatchesResultDataAll([]);
    setMatchesData([]);
    setAllOrderData([]);

    getSportData("id=" + id + "&sportType=" + sportType).then((data) => {
      //console.log(data.data);
      setSelectedComp(name);
      setSelectedCompType(compTypeName);
      setCompUniverData(data.data.compUniversity?.universityData);

      setImagesData(data.data.images);
      setNewsData(data.data.news);
      setVidiosData(data.data.vidios);

      setImagesDataActive(true);
    });

    getSportResult("id=" + id + "&sportType=" + sportType).then((data) => {

      setSelectedComp(name);
      setSelectedCompType(compTypeName);

      if (data.data.detailsData) {
        const dataD = [
          {
            name: name,
            compType: compType,
            image: image,
            detaild: data.data.detailsData,
          },
        ];
        setCompTeamResult(dataD);
      }

      // team matches
      if (
        data.data.result.length > 0 &&
        data.data.result[0].univerPoints === undefined
      ) {
        const rrrData = data.data.result
          .map((x) => {
            return x.matchTeam;
          })
          .flat()
          // .filter(function (el) {
          //   return el.team1Score >= 0 || el.team2Score >= 0;
          // })
          .map((x) => {
            return {
              id: x._id,
              sportLogo: image,
              sportName: name,
              matchDate: x.dateTime,
              team1Logo: "",
              team1Id: x.team1Id,
              team1Name: x.team1Name,
              team1Score: x.team1Score ?? "-",
              team2Logo: "",
              team2Id: x.team2Id,
              team2Name: x.team2Name,
              team2Score: x.team2Score ?? "-",
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();

        setMatchesResultData(rrrData);
        setMatchesResultDataAll(rrrData);
        setCompIndivFlag(false);

        setAllOrderData(data.data.teamsOrder);
      }

      // indevidual score
      if (data.data.result.length > 0 && data.data.result[0].univerPoints) {

        setCompIndivScore(data.data.result);
        setCompIndivFlag(true);
      }
    });

    getLatestMatchesResult("compId=" + id).then((data) => {
      if (data && data.data && data.data.matches) {
        const latestMatchData = data.data.matches
          .slice(0, data.data.matches.length > 1 ? 1 : data.data.matches.length)
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();


        setMatchesData(latestMatchData);
      }

    });

  };

  const mediaActiv = (tabNum) => {
    setImagesDataActive(false);
    setVidiosDataActive(false);
    setNewsDataActive(false);
    if (tabNum === 1) { setImagesDataActive(true); }
    if (tabNum === 2) { setVidiosDataActive(true); }
    if (tabNum === 3) { setNewsDataActive(true); }
  }

  const [isOpen, setisOpen] = useState(false);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  const [videoID, setVideoID] = useState('');

  const playVideo = (id) => {
    setVideoID(id);
    setisOpen(!isOpen);
  }

  const selectSeassonFunc = (sessonId, seassonName) => {
    setCurrentSeassonName(seassonName);
    setCurrentSeassonId(sessonId);
    setAllOrderData([]);
    setCompIndivScore([]);
    setCompTeamResult([]);
    setSelectedComp("");
    setSelectedCompNameFilter(t("competitions"));
    setSelectedCompFilter("");

    getSessons("id=" + sessonId).then((data) => {
      setMainSeassonsData(data.data);

      if (data.data && data.data.length > 0) {

        const gatheringData = data.data[0].gatheringPlaces;
        setGatheringData(
          gatheringData.slice(
            0,
            gatheringData.length > 7 ? 7 : gatheringData.length
          )
        );

        setCurrentSeassonCompet(
          data.data[0].competions
        );
        setCurrentSeassonCompetAll(
          data.data[0].competions
        );


        // OrdersForTeams
        let competionsTeamList = data.data[0].competions.filter(function (el) {
          return el.sportType === 2;
        });

        let SeassonTeamResultArr = [];
        if (competionsTeamList.length > 0) {
          competionsTeamList.forEach((item) => {
            if (
              SeassonTeamResultArr.filter(function (el) {
                return el.id === item._id;
              }).length <= 0
            ) {
              getCompetionResult(
                "id=" + item._id + "&sportType=" + item.sportType
              ).then((data) => {
                SeassonTeamResultArr.push({
                  id: item._id,
                  headr: {
                    name: item.name,
                    nameEn: item.nameEn,
                    image: item.image,
                    sportType: item.sportType,
                    compType: item.compType,
                  },
                  detaild: data?.data?.detailsData,
                });

                setSeassonTeamResult(Array.from(new Set(SeassonTeamResultArr)));
              });
            }
          });
        }

      }
    });

    getLatestMatchesResult("id=" + sessonId).then((data) => {
      if (data && data.data && data.data.matches) {
        const latestMatchData = data.data.matches
          .slice(0, data.data.matches.length > 1 ? 1 : data.data.matches.length)
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();

        setMatchesData(latestMatchData);
      }

      if (data && data.data && data.data.resultData) {
        const latestResult = data.data.resultData
          .slice(
            0,
            data.data.resultData.length > 200 ? 200 : data.data.resultData.length
          )
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              team1Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team1Score
                : "-",
              team2Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team2Score
                : "-",
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();

        setMatchesResultData(latestResult);
        setMatchesResultDataAll(latestResult);
      }
    });

  }

  const selectCompTypeFunc = (compType, compTypeName) => {
    setSelectedCompTypeFilter(compType);
    setSelectedCompTypeNameFilter(compTypeName);
    setAllOrderData([]);
    setCompIndivScore([]);
    setCompTeamResult([]);
    setSelectedComp("");
    setSelectedCompNameFilter(t("competitions"));
    setSelectedCompFilter("");

    setCurrentSeassonCompet(currentSeassonCompetAll.filter(function (el) {
      return el.compType === compType;
    }));


    getLatestMatchesResult("id=" + currentSeassonId + "&compType=" + compType).then((data) => {
      setMatchesData([]);
      setMatchesResultData([]);
      setMatchesResultDataAll([]);
      setSeassonTeamResult([]);
      setAllOrderData([]);

      if (data && data.data && data.data.matches) {
        const latestMatchData = data.data.matches
          .slice(0, data.data.matches.length > 1 ? 1 : data.data.matches.length)
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();
        setMatchesData(latestMatchData);
      }

      if (data && data.data && data.data.resultData) {
        const latestResult = data.data.resultData
          .slice(
            0,
            data.data.resultData.length > 200 ? 200 : data.data.resultData.length
          )
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              team1Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team1Score
                : "-",
              team2Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team2Score
                : "-",
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();

        setMatchesResultData(latestResult);
        setMatchesResultDataAll(latestResult);
      }
    });


    let competionsTeamList = currentSeassonCompetAll.filter(function (el) {
      return el.sportType === 2 && el.compType === compType;
    });
    console.clear();
    console.log(competionsTeamList)
    let SeassonTeamResultArr2 = [];
    if (competionsTeamList.length > 0) {
      competionsTeamList.forEach((item) => {
        if (
          SeassonTeamResultArr2.filter(function (el) {
            return el.id === item._id;
          }).length <= 0
        ) {
          getCompetionResult(
            "id=" + item._id + "&sportType=" + item.sportType
          ).then((data) => {
            SeassonTeamResultArr2.push({
              id: item._id,
              headr: {
                name: item.name,
                nameEn: item.nameEn,
                image: item.image,
                sportType: item.sportType,
                compType: item.compType,
              },
              detaild: data?.data?.detailsData,
            });

            setSeassonTeamResult(Array.from(new Set(SeassonTeamResultArr2)));
          });
        }
      });
    }
  }

  // const navigate = useNavigate();
  const handleClick = () => {
    window.location = "/competitions";
    // navigate("/competitions");
  }

  return (
    <div className="competitions__container">
      <div className="competitions_heading">
        <div className="overlay"></div>
        <img
          className="competitions_headingImg"
          src={images.headingImg}
          alt=""
        />
        <img className="headingIcon" src={images.headingIcon} alt="" />
      </div>
      <div className="main">
        <div className="row competition-row">
          <div className="col-xl-3 col-md-5 rightSide">
            <div className="filters">
              {isNotDetails && (<>
                {/* seasons dorpdown */}
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="seasonMenu"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="label">
                      <img src={images.seasonMenu} alt="" />
                      <p>{currentSeassonName} </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="seasonMenu">
                    {allSeassonNameList.length > 0 ? (
                      <>
                        {allSeassonNameList.map((item, index) => (
                          <>
                            <a onClick={() => selectSeassonFunc(item.id, i18n.language == "ar" ? item.name : item.nameEn)} className="dropdown-item" href="#!" index={index}>
                              {i18n.language == "ar" ? item.name : item.nameEn}
                            </a>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                    {/*
                  <a className="dropdown-item" href="#">
                    المـــوسم الاول
                  </a>
                  <a className="dropdown-item" href="#">
                    المـــوسم الثانى
                  </a> */}
                  </div>
                </div>
                {/* students dropdown */}
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="studentsMenu"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="label">
                      <p>{selectedCompTypeNameFilter} </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="studentsMenu">
                    <a className="dropdown-item" href="#!" onClick={() => selectCompTypeFunc(1, t("theMale"))}>
                      {t("theMale")}
                    </a>
                    <a className="dropdown-item" href="#!" onClick={() => selectCompTypeFunc(2, t("theFemale"))}>
                      {t("theFemale")}
                    </a>
                  </div>
                </div>
                {/* competiotions dropdown */}
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="compMenu"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="label">
                      <p>{selectedCompNameFilter} </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="studentsMenu">
                    {currentSeassonCompet.length > 0 ? (
                      <>
                        {currentSeassonCompet.map((item, index) => (
                          <>
                            <a className="dropdown-item" href="#!" onClick={() => getCompData(item._id, item.sportType, i18n.language == "ar" ? item.name : item.nameEn, item.compType == 1 ? t("theMale") : t("theFemale"), item.compType, item.image)}>
                              {i18n.language == "ar" ? item.name : item.nameEn} -{" "}
                              {item.compType == 1 ? t("theMale") : t("theFemale")}
                            </a>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <a className="dropdown-item" href="#">
                    comp 1
                  </a>
                  <a className="dropdown-item" href="#">
                    comp 2
                  </a> */}
                  </div>
                </div>
              </>)}

              <div className="dropdown displayNone">
                <button
                  className="btn"
                  type="button"
                  id="studentsMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="label">
                    <p>{t("university")} </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </button>
                <div className="dropdown-menu" aria-labelledby="studentsMenu">
                  {compUniverData.map((item, index) => (
                    <>
                      <a className="dropdown-item" href="#!">
                        {i18n.language == "ar" ? item.name : item.nameEn}
                      </a>
                    </>
                  ))}

                  {/* <a className="dropdown-item" href="#">
                    uni 1
                  </a>
                  <a className="dropdown-item" href="#">
                    uni 2
                  </a> */}
                </div>
              </div>

              {/* date picker */}
              <input className="datePicker displayNone" placeholder={t("date")} type="date" />
              {/* advanced features */}
              {/* <div className="features">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
                <p>Advanced Features</p>
              </div> */}
              {/* clear filters button */}

              <button onClick={handleClick} className="clearFilter">
                {isNotDetails ? t("clearSearch") : t("competitions")}

              </button>

            </div>

            {isNotDetails && (<>
              {toggleSports && (
                <div className="sports">
                  {currentSeassonCompet.length > 0 ? (
                    <>
                      {currentSeassonCompet.map((item, index) => (
                        <>
                          <div
                            className="sportCard"
                            index={index}
                            onClick={() =>
                              getCompData(
                                item._id,
                                item.sportType,
                                item.name,
                                item.compType == 1 ? t("theMale") : t("theFemale"),
                                item.compType,
                                item.image
                              )
                            }
                          >
                            <div className="cardImg">
                              <img src={item.image} alt="" />
                            </div>
                            <p>
                              {i18n.language == "ar" ? item.name : item.nameEn} -{" "}
                              {item.compType == 1 ? t("theMale") : t("theFemale")}
                            </p>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>)}

            {!isMatchDetails && selectedComp && (
              <div className="gatherings">
                <p className="headingText">{t("gatherings")}</p>
                <Slider {...scoresSlider}>
                  {gatheringData.map((gathering, index) => (
                    <>
                      <div className="gathering-slideBody" index={index}>
                        <p className="location">{i18n.language == "ar" ? gathering.cityName : gathering.cityNameEn}</p>
                        <div className="range">
                          <div className="from">
                            <p className="circle">{t("from")}</p>
                            <p className="date">
                              {
                                i18n.language == "ar" ?
                                  (<>{format(Date.parse(gathering?.dateFrom), "dd MMMM, yyyy", { locale: arSA })}</>) :
                                  (<>{format(Date.parse(gathering?.dateFrom), "dd MMMM, yyyy", {})}</>)
                              }

                            </p>
                          </div>
                          <div className="to">
                            <p className="circle">{t("To")}</p>
                            <p className="date">
                              {
                                i18n.language == "ar" ?
                                  (<>{format(Date.parse(gathering?.dateTo), "dd MMMM, yyyy", { locale: arSA })}</>) :
                                  (<>{format(Date.parse(gathering?.dateTo), "dd MMMM, yyyy", {})}</>)
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* <div className="gathering-slideBody">
                    <p className="location">الرياض</p>
                    <div className="range">
                      <div className="from">
                        <p className="circle">من</p>
                        <p className="date">05 أكتوبر, 2022</p>
                      </div>
                      <div className="to">
                        <p className="circle">الي</p>
                        <p className="date">01 ديسمبر, 2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="gathering-slideBody">
                    <p className="location">الرياض</p>
                    <div className="range">
                      <div className="from">
                        <p className="circle">من</p>
                        <p className="date">05 أكتوبر, 2022</p>
                      </div>
                      <div className="to">
                        <p className="circle">الي</p>
                        <p className="date">01 ديسمبر, 2022</p>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            )}
            {!isMatchDetails && selectedComp && (
              <div className="participants">
                <p className="title">{t("particiUniversity")}</p>
                <hr />
                <div className="participant-list">
                  {compUniverData.map((item, index) => (
                    <>
                      <div className="participant-card">
                        <img src={item.image} alt="" />
                        <p>{i18n.language == "ar" ? item.name : item.nameEn}</p>
                      </div>
                    </>
                  ))}

                  {/* <div className="participant-card">
                    <img src={images.kingAbdelaziz} alt="" />
                    <p>جامعة الملك عبدالعزيز</p>
                  </div>
                  <div className="participant-card">
                    <img src={images.islamicUni} alt="" />
                    <p>الجامعة الإسلامية</p>
                  </div>
                  <div className="participant-card">
                    <img src={images.kingAbdelaziz} alt="" />
                    <p>جامعة الملك عبدالعزيز</p>
                  </div>
                  <div className="participant-card">
                    <img src={images.islamicUni} alt="" />
                    <p>الجامعة الإسلامية</p>
                  </div>
                  <div className="participant-card">
                    <img src={images.kingAbdelaziz} alt="" />
                    <p>جامعة الملك عبدالعزيز</p>
                  </div>
                  <div className="participant-card">
                    <img src={images.islamicUni} alt="" />
                    <p>الجامعة الإسلامية</p>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-9 col-md-7 leftSide">
            <div className="seasonHeading">
              <div className="heading__rightSide">
                <h1>{currentSeassonName}</h1>

                {selectedComp && (
                  <>
                    <p className="headingSport">
                      <span></span>
                      {selectedComp}
                    </p>
                    <p className="headingAudience">
                      <span></span>
                      {selectedCompType}
                    </p>
                  </>
                )}
              </div>
              {/* <div className="heading__leftSide">
                <p>الثلاثاء 11 أبريل 2023</p>
              </div> */}
            </div>
            {isMatchDetails == "" ? (<>

              {selectedComp && (
                <>
                  <div className="mediaTab">
                    <div onClick={() => mediaActiv(1)} className={imagesDataActive ? "pill photosPill active" : "pill photosPill "}>
                      <div className="imgBox">
                        <img src={images.gallery} alt="" />
                      </div>
                      <p>{t("nav.news.images")} </p>
                    </div>
                    <div onClick={() => mediaActiv(2)} className={vidiosDataActive ? "pill videosPill active" : "pill videosPill "}>
                      <div className="imgBox">
                        <img src={images.media} alt="" />
                      </div>
                      <p>{t("nav.news.vidios")}</p>
                    </div>
                    <div onClick={() => mediaActiv(3)} className={newsDataActive ? "pill newsPill active" : "pill newsPill"}>
                      <div className="imgBox">
                        <img src={images.message} alt="" />
                      </div>
                      <p>{t("nav.news.media")}</p>
                    </div>
                  </div>
                  <div className="mediaContent">

                    {newsDataActive && newsData.length > 0 ? (
                      <>
                        <div className="news-list container">
                          <div className="row">
                            {newsData.map((newsItem, index) => (
                              <>
                                <div
                                  className="col-lg-4 col-md-6 col-sm-12 news-card"
                                  index={index}
                                >
                                  <div className="card-img">
                                    <img src={newsItem.image} alt="" />
                                  </div>
                                  <div className="card-body">
                                    <div className="card-body__top">
                                      <p className="news-date">
                                        <span>
                                          {
                                            i18n.language == "ar" ?
                                              (<>{format(new Date(newsItem.publishDate), "dd MMMM , yyyy", { locale: arSA })}</>) :
                                              (<>{format(new Date(newsItem.publishDate), "dd MMMM , yyyy", { })}</>)
                                          }

                                          
                                        </span>
                                        <img src={images.calendar} alt="" />
                                      </p>
                                      <NavLink to={`/details/${newsItem.id}`}>
                                        <p className="news-text">{i18n.language == "ar" ? newsItem.title : newsItem.titleEn}</p>
                                      </NavLink>
                                    </div>
                                    <div className="card-body__bottom">
                                      <hr />
                                      <p className="news-location">{t("riad")}</p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </>) : (<></>)}

                    <div className="row">

                      {imagesDataActive && imagesData.length > 0 ? (
                        <>
                          <div class="grid">
                            <Gallery>
                              {imagesData.map(photo => (
                                <Item
                                  original={photo.image}
                                  thumbnail={photo.image}
                                  width="800"
                                  height="600"
                                >
                                  {({ ref, open }) => (
                                    <img ref={ref} onClick={open} src={photo.image} />
                                  )}
                                </Item>
                              ))}
                            </Gallery>
                          </div>
                        </>
                      ) : ''}


                      {vidiosDataActive && vidiosData.length > 0 ? (
                        <>

                          {vidiosData.map((video, index) => (
                            <>
                              <div className="col-lg-4 col-md-6 col-sm-12 news-card" index={index}>
                                <div className="media-box" onClick={() => playVideo(video.url)}>
                                  <img
                                    src={video.image}
                                    alt="main-img"
                                    className="main-img"
                                  />
                                  <img
                                    src={images.playOutline}
                                    alt="play"
                                    className="play-img"
                                  />
                                </div>
                              </div>
                            </>
                          ))}

                          <ModalVideo channel="youtube" isOpen={isOpen} videoId={videoID} onClose={openModal} />
                        </>
                      ) : (<></>)}
                      {/* <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div>
                    <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div>
                    <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div>
                    <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div>
                    <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div>
                    <div className="mediaCol col-md-4">
                      <img src={images.playerImg} alt="" />
                    </div> */}
                    </div>
                  </div>
                </>
              )}


            </>) : (<></>)}
            <Outlet
              context={[
                latestMatchesResult,
                latestMatchesResultAll,
                latestMatch,

                setMatchesData,
                setMatchesResultData,
                setMatchesResultDataAll,

                setMainSeassonsData,
                setCurrentSeassonName,
                setAllSeassonNameList,
                setCurrentSeassonCompet,
                setCurrentSeassonCompetAll,
                setSeassonTeamResult,
                seassonTeamResult,
                setCompTeamResult,
                compTeamResult,
                compUniverData,
                compIndivScore,
                setCompIndivScore,
                compIndivFlag,
                setCompIndivFlag,
                isMatchDetails, setMatchDetails,
                allOrderData, setAllOrderData,
                setSelectedComp,
                setIsNotDetails
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
