import globe from "../Assets/nav-actions/global.svg";
import ethiad_org from "../Assets/subnav/etihad/organization.svg";
import ethiad_card from "../Assets/subnav/etihad/personalcard.svg";
import ethiad_orgWhite from "../Assets/subnav/etihad/organizationWhite.svg";
import ethiad_cardWhite from "../Assets/subnav/etihad/personalcardWhite.svg";
import gallery from "../Assets/subnav/media/gallery.svg";
import galleryWhite from "../Assets/subnav/media/galleryWhite.svg";
import ads from "../Assets/subnav/media/ads.svg";
import adsWhite from "../Assets/subnav/media/adsWhite.svg";
import media from "../Assets/subnav/media/media.svg";
import mediaWhite from "../Assets/subnav/media/mediaWhite.svg";
import message from "../Assets/subnav/media/message.svg";
import messageWhite from "../Assets/subnav/media/messageWhite.svg";
import printer from "../Assets/subnav/media/printer.svg";
import printerWhite from "../Assets/subnav/media/printerWhite.svg";
import profile from "../Assets/subnav/media/profile.svg";
import profileWhite from "../Assets/subnav/media/profileWhite.svg";
import scissor from "../Assets/subnav/media/scissor.svg";
import scissorWhite from "../Assets/subnav/media/scissorWhite.svg";
import email from "../Assets/subnav/e-services/email.svg";
import emailWhite from "../Assets/subnav/e-services/emailWhite.svg";
import briefcase from "../Assets/subnav/e-services/brifecase.svg";
import briefcaseWhite from "../Assets/subnav/e-services/briefcaseWhite.svg";
import gear from "../Assets/subnav/e-services/gear.svg";
import gearWhite from "../Assets/subnav/e-services/gearWhite.svg";
import group from "../Assets/subnav/e-services/group.svg";
import groupWhite from "../Assets/subnav/e-services/groupWhite.svg";
import status from "../Assets/subnav/e-services/status.svg";
import statusWhite from "../Assets/subnav/e-services/statusWhite.svg";
import trophy from "../Assets/subnav/e-services/trophy.svg";
import trophyWhite from "../Assets/subnav/e-services/trophyWhite.svg";
import activities from "../Assets/Homepage/main/activities.svg";
import activitiesGreen from "../Assets/Homepage/main/activities-green.svg";
import indicator from "../Assets/Homepage/main/indicator.svg";
import indicatorGreen from "../Assets/Homepage/main/indicator-green.svg";
import kingSo3od from "../Assets/Homepage/matchSides/kingSo3od.png";
import faisalUni from "../Assets/Homepage/matchSides/faisalUni.png";
import islamicUni from "../Assets/Homepage/matchSides/islamicUni.png";
import mohamedBenSo3od from "../Assets/Homepage/matchSides/mohamedBenSo3od.png";
import QusaimUni from "../Assets/Homepage/matchSides/QusaimUni.png";
import saudiElectronic from "../Assets/Homepage/matchSides/saudiElectronic.png";
import boy from "../Assets/Homepage/matchSides/boy.svg";
import boyWhite from "../Assets/Homepage/matchSides/boyWhite.svg";
import girl from "../Assets/Homepage/matchSides/girl.svg";
import arrowDown from "../Assets/Homepage/matchSides/arrowDown.svg";
import girls from "../Assets/Homepage/counters/girls.svg";
import trophies from "../Assets/Homepage/counters/trophy.svg";
import ranks from "../Assets/Homepage/counters/ranks.svg";
import uni from "../Assets/Homepage/counters/uni.svg";
import season from "../Assets/Homepage/counters/season.svg";
import football from "../Assets/Homepage/Competitions/football.svg";
import footballWhite from "../Assets/Homepage/Competitions/footballWhite.svg";
import tennis from "../Assets/Homepage/Competitions/tennis.svg";
import tennisWhite from "../Assets/Homepage/Competitions/tennisWhite.svg";
import volleyball from "../Assets/Homepage/Competitions/volleyball.svg";
import volleyballWhite from "../Assets/Homepage/Competitions/volleyballWhite.svg";
import handball from "../Assets/Homepage/Competitions/handball.svg";
import handballWhite from "../Assets/Homepage/Competitions/handballWhite.svg";
import tableTennis from "../Assets/Homepage/Competitions/tableTennis.svg";
import tableTennisWhite from "../Assets/Homepage/Competitions/tableTennisWhite.svg";
import comp from "../Assets/Homepage/Competitions/comp.svg";
import arrowLeft from "../Assets/Homepage/News/arrowLeft.svg";
import arrowRight from "../Assets/Homepage/News/arrowRight.svg";
import newsImg from "../Assets/Homepage/News/news-img.png";
import download from "../Assets/download.svg";
import menu from "../Assets/menu.svg";
import boys from "../Assets/Homepage/counters/boys.svg";
import close from "../Assets/close.svg";
import headingImg from "../Assets/Competitions/headingImg.png";
import headingIcon from "../Assets/Competitions/headingIcon.svg";
import seasonMenu from "../Assets/Competitions/seasonMenu.svg";
import hockey from "../Assets/Competitions/sports/hockey.svg";
import basketball from "../Assets/Competitions/sports/basketball.svg";
import waterball from "../Assets/Competitions/sports/waterball.svg";
import kingAbdelaziz from "../Assets/Competitions/unis/kingAbdelaziz.png";
import vision from "../Assets/etihad/about/vision.png";
import aboutMessage from "../Assets/etihad/about/message.png";
import goals from "../Assets/etihad/about/goals.png";
import ActivitiesHeading from "../Assets/MediaCenter/Activities/headingImg.jpg";
import cardImg from "../Assets/MediaCenter/Activities/cardImg.png";
import calendar from "../Assets/MediaCenter/News/calendar.svg";
import calendarWhite from "../Assets/MediaCenter/News/calendarWhite.svg";
import playOutline from "../Assets/MediaCenter/play_outline.svg";
import extlink from "../Assets/MediaCenter/ext-link.svg";
import playerImg from "../Assets/Competitions/playerImg.jpg";
import matchEvents from "../Assets/Competitions/matchEvents.svg";
import time from "../Assets/Competitions/time.svg";
import timeWhite from "../Assets/Competitions/timeWhite.svg";
import yellowCard from "../Assets/Competitions/YellowCard.svg";
import redCard from "../Assets/Competitions/redCard.svg";
import penalty from "../Assets/Competitions/penalty.svg";
import calendarGreen from "../Assets/MediaCenter/calendar-green.svg";
import locationGreen from "../Assets/MediaCenter/location-green.svg";
import leader from "../Assets/etihad/leader.jpeg";
import leaderIcon from "../Assets/etihad/leaderIcon.svg";
import certificates from "../Assets/etihad/certificates.svg";
import currentRole from "../Assets/etihad/currentRole.svg";
import termsConditions from "../Assets/terms.svg";
import communicate from "../Assets/communicate.svg";
import aboutEtihad from "../Assets/etihad/about.svg";

export default {
  aboutEtihad,
  certificates,
  currentRole,
  leaderIcon,
  leader,
  yellowCard,
  redCard,
  penalty,
  time,
  timeWhite,
  matchEvents,
  playerImg,
  calendar,
  calendarWhite,
  cardImg,
  ActivitiesHeading,
  vision,
  aboutMessage,
  goals,
  kingAbdelaziz,
  hockey,
  basketball,
  waterball,
  seasonMenu,
  headingImg,
  headingIcon,
  activitiesGreen,
  indicatorGreen,
  close,
  boys,
  menu,
  download,
  newsImg,
  arrowLeft,
  arrowRight,
  comp,
  football,
  footballWhite,
  tennis,
  tennisWhite,
  volleyball,
  volleyballWhite,
  tableTennis,
  tableTennisWhite,
  handball,
  handballWhite,
  globe,
  ethiad_card,
  ethiad_cardWhite,
  ethiad_org,
  ethiad_orgWhite,
  gallery,
  galleryWhite,
  ads,
  adsWhite,
  media,
  mediaWhite,
  message,
  messageWhite,
  printer,
  printerWhite,
  profile,
  profileWhite,
  scissor,
  scissorWhite,
  email,
  emailWhite,
  briefcase,
  briefcaseWhite,
  group,
  groupWhite,
  gear,
  gearWhite,
  status,
  statusWhite,
  trophy,
  trophyWhite,
  activities,
  indicator,
  kingSo3od,
  QusaimUni,
  faisalUni,
  mohamedBenSo3od,
  saudiElectronic,
  islamicUni,
  boy,
  boyWhite,
  girl,
  arrowDown,
  girls,
  ranks,
  trophies,
  uni,
  season,
  playOutline,
  extlink,
  calendarGreen,
  locationGreen,
  termsConditions,
  communicate,
};
