import "./EtihadLeader.scss";
import { images } from "../../../constants";

const EtihadLeader = () => {
  return (
    <div className="leader__container">
      <div className="top-card">
        <div className="bgLines">
          <div className="line greenLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>السيرة الذاتية</p>
            </div>
          </div>
          <div className="line centerLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>لرئيس الاتحاد الرياضي للجامعات السعودية</p>
            </div>
          </div>
          <div className="line greenLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>أ.د. خالد بن صالح المزيني</p>
            </div>
          </div>
        </div>
        <div className="leaderImg-container">
          <div className="yellowBox"></div>
          <div className="leaderImg"></div>
          <div className="iconCircle">
            <img src={images.leaderIcon} alt="" />
          </div>
        </div>
        <div className="splitLine ">
          <div class="line-with-circles"></div>
        </div>
      </div>

      <div className="mainPoints">
        <div className="side rightSide-Points ">
          <img src={images.certificates} alt="" />
          <p className="side-title">المؤهلات العلمية</p>

          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">31-12-1996</p>
              <p className="certTitle">
                دكتوراه الفلسفة فى فسيولوجيا الجهد البدنى (مع تخصص فرعى فى
                الاحصاء)
              </p>
              <p className="certProvider">
                جامعة كانساس-الولايات المتحدة الأمريكية. عنوان الرسالة:
              </p>
              <p className="certMessage">
                "Effects of Split Exercise Sessions on Excess Post-Exercise
                Oxygen Consumption and Resting Metabolic Rate"
              </p>
            </div>
          </div>
          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">13-12-1993</p>
              <p className="certTitle">ماجستير في فسيولوجيا الجهد البدني</p>
              <p className="certProvider">
                جامعة كانساس-الولايات المتحدة الأمريكية. عنوان الرسالة:
              </p>
              <p className="certMessage">
                "Validity of Bioelectrical Impedance Analysis in The Prediction
                of Percent Body Fat in Men"
              </p>
            </div>
          </div>
          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">8-11-1989</p>
              <p className="certTitle">
                بكالوريوس تربية، تخصص تربية بدنية (مع مرتبة الشرف الثانية)
              </p>
              <p className="certProvider">جامعة الملك سعود, الرياض</p>
            </div>
          </div>
        </div>
        <div className="splitLine ">
          <div class="line-with-circles"></div>
        </div>
        <div className="side leftSide-Points">
          <img src={images.currentRole} alt="" />
          <p className="side-title">العمل الحالي</p>
          <ul className="role-list">
            <li className="role">
              <span>
                رئيس الاتحاد الرياضي للجامعات السعودية، وزارة التعليم.
              </span>
            </li>
            <li className="role">
              <span>نائب رئيس الاتحاد الآسيوي للرياضة الجامعية.</span>
            </li>
            <li className="role">
              <span>
                عضو مجلس إدارة الأكاديمية الأولمبية السعودية، اللجنة الأولمبية
                والبارالمبية السعودية، وزارة الرياضة.
              </span>
            </li>
            <li className="role">
              <span>
                رئيس تحرير مجلة علوم الرياضة والتربية البدنية وأستاذ فسيولوجيا
                الجهد البدني- كلية علوم الرياضة والنشاط البدني- جامعة الملك
                سعود- الرياض.
              </span>
            </li>
            <li className="role">
              <span>
                رئيس اللجنة السعودية لمعايير اللياقة البدنية- وزارة الرياضة.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="about">
        <p className="aboutHeading">كلمة رئيس الاتحاد</p>
        <p className="aboutPoint">
          يسعى الاتحاد الرياضي للجامعات السعودية لبناء نموذج وطني للرياضة
          الجامعية متوائم مع أفضل الممارسات الدولية، بما يسمح له من تحقيق أهداف
          رؤية المملكة المرتبطة بالنشاط البدني والرياضي، وذلك من خلال رياضة
          جامعية يرتفع فيها نسبة المشاركين بمختلف قدراتهم ومستوياتهم وأهدافهم،
          وتتحقق فيها عدالة الفرص بين الجنسين وبين الممارسين، ويتحقق من خلالها
          تمثيل دولي يلائم مكانة وإمكانات المملكة، لتكون الرياضة الجامعية من
          أكبر الداعمين لرياضة الوطن. ومن هذا المنطلق يقوم الاتحاد حالياً بتنفيذ
          حزمة من المشاريع والبرامج التطويرية لتمكين بيئة الرياضة الجامعية من
          تحقيق هذه الأهداف الاستراتيجية.
        </p>
        <p className="aboutPoint">
          ومن أهم هذه المشاريع الاستراتيجية هي مبادرة إعادة تأهيل البنية التحتية
          والتشغيلية للمنشآت الرياضية في الجامعات، والذي بدوره سيسمح بزيادة
          مساحات وأماكن ممارسة النشاط البدني والتنافس الرياضي، ويحفز شرائح
          متنوعة الأهداف من المهتمين بممارسة النشاط البدني والرياضي. أيضاً يشمل
          هذا المشروع تدريب وتأهيل العاملين في هذه المنشآت الرياضية والقيادات
          الرياضية.
        </p>
        <p className="aboutPoint">
          وتوسع الاتحاد الرياضي في أنشطته وبرامجه لتشمل عدة شرائح من المجتمع
          الجامعي وذلك لتلبية احتياجات وأهداف الممارسين المختلفة، مثل الممارسة
          من أجل الصحة أو الترويح أو التنافس وصولاً إلى رياضيي النخبة
          والمنتخبات. ويتم حاليا تجهيز مراكز رياضيي النخبة وهي عبارة عن حاضنات
          تهتم بهؤلاء الرياضيين المتميزين طوال العام وتقدم لهم الاختبارات
          البدنية والفسيولوجية والنفسية وكل ما يعينهم على تحقيق المنجزات في
          المنافسات الدولية. ويدعم الاتحاد في جميع أنشطته تكافؤ الفرص بين
          الجنسين (الطلاب والطالبات)، كما يخطط الاتحاد لتكون الرياضة الجامعية
          بيئة حاضنة ومطورة للرياضة النسائية في المملكة. وتهتم برامج الاتحاد في
          مختلف القدرات ومستويات الأداء، كما يقدم الاتحاد برامج متنوعة وأنشطة
          ومنافسات لذوي الاعاقة.
        </p>
        <p className="aboutPoint">
          ولأهمية التوعية والتثقيف، وخصوصاً لكون البيئة الجامعية خصبة للتعلم
          ولترسيخ مجموعة من القيم والمبادئ السامية، يطلق الاتحاد برامج ومسابقات
          توعوية متنوعة والكثير من الفعاليات والفيديوهات التوعوية للمجتمع
          الجامعي، والتي صممت لتتوائم مع الأهداف الاستراتيجية للاتحاد وتحقق
          المستوى الثقافي المأمول من الناحية الصحية والتنافسية. ولذلك يجمع
          الاتحاد جميع أنشطته في بوابة الكترونية تشمل عدة منصات، منها منصة
          الأهلية ومنصة المنافسات في الألعاب المختلفة ومنصة المسابقات التوعوية،
          كل ذلك رغبة في أتمتة كامل أعمال وأنشطة وإجراءات الاتحاد ليسهل التعامل
          معها ولتصبح قاعدة معلومات يستفاد منها في المشاريع القادمة لمزيد من
          التطوير في الرياضة الجامعية. وأخيراً فلا تخفى أهمية الرياضة الجامعية
          حيث يمكن أن تساهم في دعم رياضة الوطن من خلال التالي:
        </p>
        <ul>
          <li>
            زيادة عدد الرياضيين والفرق المشاركة في المسابقات الدولية، حيث يوجد
            اتحاد دولي للرياضة الجامعية في عضويته حوالي 170 دولة ينظم مسابقات
            دولية يتنافس فيها أفضل الرياضيين في العالم.
          </li>
          <li>
            تطوير وتشجيع رياضات جديدة ذات شعبية وأهمية عالمية لكنها ليست شعبية
            على المستوى المحلي.
          </li>
          <li>فتح المجال لممارسة التحكيم في المنافسات الجامعية.</li>
          <li>
            فتح المجال لكافة المستويات الفنية للشباب لممارسة الرياضة التنافسية
            عند مستويات قد تمكنهم للانتقال لرياضة النخبة.
          </li>
          <li>
            يمكن أن تحتضن مراكز أولمبية مصغرة داخل الجامعات لتدريب رياضيي النخبة
            طوال العام وتقديم الخدمات اللازمة مثل الاختبارات البدنية
            والفسيولوجية والبحث العلمي.
          </li>
          <li>
            تتوزع الجامعات في كل أرجاء المملكة وتوجد في مناطق الأطراف والأرياف
            وهذا سيساهم في توسيع استقطاب الرياضيين وفتح الفرصة للجميع للمشاركة
            في الرياضة.
          </li>
          <li>
            رياضة الجامعات هي فرصة للتركيز على فئة عمرية مهمة جداً للألعاب
            الأولمبية من 17-25 سنة.
          </li>
          <li>
            يمكن أن يصل المستهدف من المجتمع الجامعي إلى أكثر من 7 مليون نسمة
            وهذا يشمل الطلاب والطالبات وذويهم والكادر الأكاديمي والإداري، لذلك
            يمكن تحقيق أهداف وطنية متمثلة في زيادة عدد الممارسين للنشاط البدني
            المرتبط بالصحة.
          </li>
          <li>
            تعد بيئة ملائمة لتطوير رياضة ذوي الإعاقة والفئات الخاصة وإقامة
            البطولات لهم.
          </li>
          <li>
            توليد وظائف وإتاحة الفرصة لقيادات شابة من الجنسين لممارسة مهن قيادية
            في التدريب والتحكيم والإدارة الرياضية.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EtihadLeader;
