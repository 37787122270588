import logo from "../../Assets/Logo.png";
import Minstary from "../../Assets/Minstary.png";
import "./header.scss";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentLang(localStorage.getItem("i18nextLng"));
    console.log(currentLang);
    document.body.dir = i18n.dir();
  }, [currentLang]);

  const changeLanguage = () => {
    if (currentLang === "ar") {
      i18n.changeLanguage("en");
      //console.log("current is english", i18n.dir());
      localStorage.setItem("i18nextLng", "en");
    }
    if (currentLang === "en") {
      i18n.changeLanguage("ar");
      // console.log("current is arabic", i18n.dir());
      localStorage.setItem("i18nextLng", "ar");
    }
    setCurrentLang(localStorage.getItem("i18nextLng"));
  };

  return (
    <>
      <div className="header-container">
        <div className="header-content">
          <div className="header__main-logo">
            <NavLink to="/">
              <img src={logo} />
            </NavLink>
          </div>
          <div className="header__links">
            <div
              className="mobile-nav__toggler"
              onClick={() => {
                props.setToggleMobileNav(true);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path>
              </svg>
            </div>
            {/* <div className="links-holder">
              <div onClick={() => changeLanguage()} className="language">
                <img src={images.globe} alt="" />
                <span>{currentLang === "ar" ? "en" : "ar"}</span>
              </div>
            </div> */}
          </div>
          <div className="header__minstry-logo">
            <img src={Minstary} />
          </div>
        </div>
      </div>
    </>
  );
}
