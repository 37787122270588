import { en as home } from "./locales/en/home";

const en = {
  translation: {
    ...home,
    // Add more translations for English
  },
};

export default en;
