import React, { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import {
  getLatestMatchesResult,
  getSessons,
  getCompetionResult,
} from "../../../API/susfApi";
import { format, differenceInMinutes } from "date-fns";
import { arSA } from "date-fns/locale";
import { images } from "../../../constants";
import Slider from "react-slick";
import "./CompMain.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const CompMain = () => {
  const { t } = useTranslation();

  const [
    latestMatchesResult,
    latestMatchesResultAll,
    latestMatch,
    setMatchesData,
    setMatchesResultData,
    setMatchesResultDataAll,
    setMainSeassonsData,
    setCurrentSeassonName,
    setAllSeassonNameList,
    setCurrentSeassonCompet,
    setCurrentSeassonCompetAll,
    setSeassonTeamResult,
    seassonTeamResult,
    setCompTeamResult,
    compTeamResult,
    compUniverData,
    compIndivScore,
    setCompIndivScore,
    compIndivFlag,
    setCompIndivFlag,
    isMatchDetails, setMatchDetails,
    allOrderData, setAllOrderData,
    setSelectedComp,
    setIsNotDetails
  ] = useOutletContext();

  const { sport } = useParams();
  const [inputDate, setInputDate] = useState('');
  const [inputDate2, setInputDate2] = useState('');

  const scoresSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {


    getLatestMatchesResult().then((data) => {
      if (data && data.data && data.data.matches) {
        const latestMatchData = data.data.matches
          .slice(0, data.data.matches.length > 1 ? 1 : data.data.matches.length)
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();

        setMatchesData(latestMatchData);
      }

      let latestResult = [];

      if (data && data.data && data.data.resultData) {
        latestResult = data.data.resultData
          .slice(
            0,
            data.data.resultData.length > 200 ? 200 : data.data.resultData.length
          )
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              team1Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team1Score
                : "-",
              team2Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team2Score
                : "-",
              compType: x.matchTeam.compType,
            };
          }).sort((a, b) => {
            return new Date(a.matchDate).getTime() -
              new Date(b.matchDate).getTime()
          }).reverse();


      }

      if (latestResult == null || latestResult == undefined || latestResult.length <= 0) {
        latestResult = data.data.matches
          .slice(
            0,
            data.data.matches.length > 200 ? 200 : data.data.matches.length
          )
          .map((x) => {
            return {
              id: x.matchTeam._id,
              team1Name: x.matchTeam.team1Name,
              team1Logo: x.matchTeam.team1Logo,
              team2Name: x.matchTeam.team2Name,
              team2Logo: x.matchTeam.team2Logo,
              matchDate: x.matchTeam.dateTime,
              sportName: x.matchTeam.sportName,
              sportNameEn: x.matchTeam.sportNameEn,
              sportLogo: x.matchTeam.sportLogo,
              team1Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team1Score
                : "-",
              team2Score: x.matchTeam.scoreVisible
                ? x.matchTeam.team2Score
                : "-",
              compType: x.matchTeam.compType,
            };
          });
      }

      setMatchesResultData(latestResult);
      setMatchesResultDataAll(latestResult);
    });

    getSessons().then((data) => {
      setMainSeassonsData(data.data);
      if (data.data && data.data.length > 0) {
        if (i18n.language == "ar") {
          setCurrentSeassonName(
            data.data.filter(function (el) {
              return el.isCurrent === true;
            })[0].name
          );
        }
        else {
          setCurrentSeassonName(
            data.data.filter(function (el) {
              return el.isCurrent === true;
            })[0].name
          );
        }
        setAllSeassonNameList(
          data.data.map((x) => {
            return {
              id: x._id,
              name: x.name,
              nameEn: x.nameEn
            };
          })
        );

        let competionsList = data.data.filter(function (el) {
          return el.isCurrent === true;
        })[0].competions;

        setCurrentSeassonCompet(competionsList);
        setCurrentSeassonCompetAll(competionsList);

        // OrdersForTeams
        let competionsTeamList = competionsList.filter(function (el) {
          return el.sportType === 2;
        });

        let SeassonTeamResultArr = [];
        if (competionsTeamList.length > 0) {

          // const getData= async (item)=>{
          //   getCompetionResult("id=" + item._id + "&sportType=" + item.sportType).then((data) => {
          //     let asd={
          //       headr: { name: item.name, image: item.image, sportType: item.sportType, compType: item.compType },
          //       detaild: data.data.detailsData
          //     };
          //     SeassonTeamResultArr.push({
          //       headr: { name: item.name, image: item.image, sportType: item.sportType, compType: item.compType },
          //       detaild: data.data.detailsData
          //     });

          //     setSeassonTeamResult((SeassonTeamResultArr) => [...SeassonTeamResultArr, ...asd]);
          //   });
          // };

          competionsTeamList.forEach((item) => {
            if (
              SeassonTeamResultArr.filter(function (el) {
                return el.id === item._id;
              }).length <= 0
            ) {
              getCompetionResult(
                "id=" + item._id + "&sportType=" + item.sportType
              ).then((data) => {
                // console.log(SeassonTeamResultArr.filter(function (el) { return el.id === item._id; }).length);
                SeassonTeamResultArr.push({
                  id: item._id,
                  headr: {
                    name: item.name,
                    nameEn: item.nameEn,
                    image: item.image,
                    sportType: item.sportType,
                    compType: item.compType,
                  },
                  detaild: data?.data?.detailsData,
                });

                setSeassonTeamResult(Array.from(new Set(SeassonTeamResultArr)));
                // setSeassonTeamResult(current => [...current,
                // {
                //   id: item._id,
                //   headr: { name: item.name, image: item.image, sportType: item.sportType, compType: item.compType },
                //   detaild: data.data.detailsData
                // }]);
              });
            }
          });
          //  setSeassonTeamResult(SeassonTeamResultArr);
          // console.log(SeassonTeamResultArr);
        }
      }
    });


  }, []);

  const getMatchStatus = (element) => {
    if (element.status) {
      if (element.status === 1) {
        return (
          <div className="matchStatus live">
            <p>{t("postponedLbl")}</p>
          </div>
        );
      } else if (element.status === 2) {
        return (
          <div className="matchStatus live">
            <p>{t("cancelLbl")}</p>
          </div>
        );
      }
    } else {
      if (element.team1Score >= 0 && element.team2Score >= 0) {
        return (
          <div className="matchStatus finished">
            <p>{t("finishedLbl")}</p>
          </div>
        );
      } else {
        return (
          <div className="matchStatus upcoming">
            <p>{t("nextLbl")}</p>
          </div>
        );
      }
    }
  };

  const getUniversityLogo = (univerId) => {
    if (univerId) {
      let univerData = compUniverData.find((element) => {
        return element._id === univerId;
      });
      if (univerData) {
        return <img src={univerData.image} alt="" />;
      }
    }
    return "";
  };

  const filterDate = () => {
    // console.log(inputDate + " > " + inputDate2)
    if (inputDate && inputDate !== "" && inputDate2 && inputDate2 !== "") {
      setMatchesResultData([]);

      let filteredData = latestMatchesResultAll.filter(function (el) {
        //console.log(format(new Date(el.matchDate), "yyyy-MM-dd") + " <> " + format(new Date(inputDate), "yyyy-MM-dd"))
        return (new Date(format(new Date(el.matchDate), "yyyy-MM-dd")) >= new Date(format(new Date(inputDate), "yyyy-MM-dd"))) && (new Date(format(new Date(el.matchDate), "yyyy-MM-dd")) <= new Date(format(new Date(inputDate2), "yyyy-MM-dd")));
      }).sort((a, b) => {
        return new Date(a.matchDate).getTime() -
          new Date(b.matchDate).getTime()
      }).reverse();
      setMatchesResultData(filteredData);
    }
  }

  return (
    <>
      <div className="matchesAndResults">
        <div className="nextMatch__container">
          <h2>{t("nextMatch")}</h2>
          <hr />
          <div className="content">
            {latestMatch.length > 0 ? (
              <>
                {latestMatch.map((item, index) => (
                  <>

                    <div className="topSection">
                      <div className="matchSport">
                        <div className="sport">
                          <img src={item.sportLogo} alt="" />
                          <p>{i18n.language == "ar" ? item.sportName : item.sportNameEn}</p>
                        </div>
                      </div>
                      <div className="matchDate">
                        <p>
                          {i18n.language == "ar" ?
                            (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", { locale: arSA, })}</>) :
                            (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", {})}</>)
                          }
                        </p>
                      </div>
                    </div>
                    <div className="sides">
                      <div className="side">
                        <div className="sideImg greenBorder">
                          {item.team1Logo == "" ? (
                            <>{getUniversityLogo(item.team1Id)}</>
                          ) : (
                            <>
                              <img src={item.team1Logo} alt="" />
                            </>
                          )}
                        </div>
                        <p>{item.team1Name}</p>
                      </div>
                      <div className="side">
                        <div className="sideImg yellowBorder">
                          {item.team2Logo == "" ? (
                            <>{getUniversityLogo(item.team2Id)}</>
                          ) : (
                            <>
                              <img src={item.team2Logo} alt="" />
                            </>
                          )}
                        </div>
                        <p>{item.team2Name}</p>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="emptyData">
                  <img src="/content/images/nodata.png" alt="no data" />
                  <span>{t("noDataFound")}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="newResults__container">
          <h2>{t("latestResult")}</h2>
          <hr />
          <div className="content">
            {latestMatchesResult.length > 0 ? (
              <>
                <Slider {...scoresSlider}>
                  {latestMatchesResult.slice(0, latestMatchesResult.length > 15 ? 15 : latestMatchesResult.length).map((item, index) => (
                    <>
                      {/* {index <= 8 ? (<> */}
                      <div className="slideBody">
                        {/* <div className="tag">
                        <p>مباشر</p>
                      </div> */}
                        <div className="topSection">
                          <div className="matchSport">
                            <div className="sport">
                              <img src={item.sportLogo} alt="" />
                              <p>{i18n.language == "ar" ? item.sportName : item.sportNameEn}</p>
                            </div>
                          </div>
                          <NavLink className="matchPath" to={`/competitions/${item.id}`} >
                            {/* <NavLink className="matchPath" to={`/competitions/matchDetails/${item.id}`} > */}
                            <div>
                              <img src={images.matchEvents} alt="" />
                              <p>{t("timeLine")} </p>
                            </div>
                          </NavLink>
                          <div className="matchDate">
                            <p>
                              {i18n.language == "ar" ?
                                (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", { locale: arSA, })}</>) :
                                (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", {})}</>)
                              }

                            </p>
                            {/* <p>الثلاثاء 11 أبريل 2023</p> */}
                          </div>
                        </div>
                        <div className="score">
                          <div className="score-card__body">
                            <div className="sides">
                              <div className="side side1">
                                <div className="sideInfo">
                                  <div className="sideImg yellowBorder">
                                    {item.team1Logo == "" ? (
                                      <>{getUniversityLogo(item.team1Id)}</>
                                    ) : (
                                      <>
                                        <img src={item.team1Logo} alt="" />
                                      </>
                                    )}
                                  </div>
                                  <p>{item.team1Name}</p>
                                </div>
                                <div className="sideScore">
                                  <p>{item.team1Score}</p>
                                </div>
                              </div>
                              <div className="side side2">
                                <div className="sideScore">
                                  <p>{item.team2Score}</p>
                                </div>
                                <div className="sideInfo">
                                  <div className="sideImg greenBorder">
                                    {item.team2Logo == "" ? (
                                      <>{getUniversityLogo(item.team2Id)}</>
                                    ) : (
                                      <>
                                        <img src={item.team2Logo} alt="" />
                                      </>
                                    )}
                                  </div>
                                  <p>{item.team2Name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </>) : (<></>)} */}
                    </>
                  ))}
                </Slider>
              </>
            ) : (
              <>
                <div className="emptyData">
                  <img src="/content/images/nodata.png" alt="no data" />
                  <br />
                  <span>{t("noDataFound")}</span>
                </div>
              </>
            )}

          </div>
        </div>
      </div>

      <div className="teamsListing">
        <h1 className={sport ? "bolder" : "bold"}>{t("orderTable")}</h1>
        {sport ? <p className="selectedSport">الكرة الطائرة</p> : ""}
        {sport ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>الفريق</th>
                  <th></th>
                  <th>لعب</th>
                  <th>فوز</th>
                  <th>خسارة</th>
                  <th>تعادل</th>
                  <th>النقاط</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="uniName">جامعة الملك عبدالعزيز</td>
                  <td>
                    <img src={images.kingAbdelaziz} alt="" />
                  </td>
                  <td>1</td>
                  <td>1</td>
                  <td>0</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td className="uniName">الجامعة الإسلامية</td>
                  <td>
                    <img src={images.islamicUni} alt="" />
                  </td>
                  <td>1</td>
                  <td>1</td>
                  <td>0</td>
                  <td>0</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <>

            {/* All over cinpetion order */}
            {allOrderData.length > 0 ? (<>
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("team")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allOrderData.map(
                    (itemScore, indexScore) => (
                      <>
                        <tr>
                          <td>{itemScore.orderNum}</td>
                          <td className="uniName">
                            {itemScore.universityName}
                          </td>
                          <td>
                            <img
                              src={itemScore.univerImage}
                              alt=""
                            />
                          </td>
                        </tr>
                      </>
                    )
                  )}
                </tbody>
              </table>
            </>) : (<></>)}

            {seassonTeamResult ? (
              <>
                {seassonTeamResult.length > 0 ? (
                  <>
                    <div
                      className="accordion accordion-flush"
                      id="accordionExample"
                    >
                      {seassonTeamResult.map((item, index) => (
                        <>
                          {item.detaild.length > 0 ? (
                            <>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#col` + index}
                                    aria-expanded="true"
                                    aria-controls={`col` + index}
                                  >
                                    <div className="accordHeading">
                                      <img src={item.headr.image} alt="" />
                                      <p>
                                        {item.headr.name} -{" "}
                                        {item.headr.compType == 1
                                          ? t("male")
                                          : t("female")}
                                      </p>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`col` + index}
                                  className="accordion-collapse collapse show"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{t("team")}</th>
                                          <th></th>
                                          <th>{t("play")}</th>
                                          <th>{t("win")}</th>
                                          <th>{t("lose")}</th>
                                          <th>{t("raw")}</th>
                                          <th>{t("points")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.detaild.map(
                                          (itemScore, indexScore) => (
                                            <>
                                              <tr>
                                                <td>{indexScore + 1}</td>
                                                <td className="uniName">
                                                  {itemScore.name}
                                                </td>
                                                <td>
                                                  <img
                                                    src={itemScore.univerImage}
                                                    alt=""
                                                  />
                                                </td>
                                                <td>{itemScore.play}</td>
                                                <td>{itemScore.win}</td>
                                                <td>{itemScore.lose}</td>
                                                <td>{itemScore.draw}</td>
                                                <td>{itemScore.point}</td>
                                              </tr>
                                            </>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {compTeamResult && compTeamResult.length > 0 ? (
              <>
                <div
                  className="accordion accordion-flush"
                  id="accordionExample"
                >
                  {compTeamResult.map((item, index) => (
                    <>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#col` + index}
                            aria-expanded="true"
                            aria-controls={`col` + index}
                          >
                            <div className="accordHeading">
                              <img src={item.image} alt="" />
                              <p>
                                {item.name} -{" "}
                                {item.compType == 1 ? t("theMale") : t("theFemale")}
                              </p>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`col` + index}
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>{t("team")}</th>
                                  <th></th>
                                  <th>{t("play")}</th>
                                  <th>{t("win")}</th>
                                  <th>{t("lose")}</th>
                                  <th>{t("raw")}</th>
                                  <th>{t("points")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.detaild.map((itemScore, indexScore) => (
                                  <>
                                    <tr>
                                      <td>1</td>
                                      <td className="uniName">
                                        {itemScore.name}
                                      </td>
                                      <th>
                                        <img
                                          src={itemScore.univerImage}
                                          alt=""
                                        />
                                      </th>
                                      <td>{itemScore.play}</td>
                                      <td>{itemScore.win}</td>
                                      <td>{itemScore.lose}</td>
                                      <td>{itemScore.draw}</td>
                                      <td>{itemScore.point}</td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <>
                {compIndivScore.length > 0 && (<>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("team")}</th>
                        <th></th>
                        <th>{t("points")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {compIndivScore.map((item, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td className="uniName">
                              {item.universityData?.name}
                            </td>
                            <td>
                              <img
                                src={item.universityData?.image}
                                alt=""
                              />
                            </td>
                            <td>{item.univerPoints?.points}</td>

                          </tr>

                        </>
                      ))}
                    </tbody>
                  </table>
                </>)}
              </>
            )}

          </>
        )}
      </div>
      <div className="matches">
        {latestMatchesResult.length > 0 ? <h1>{t("matches")} </h1> : ""}
        {sport ? <p className="selectedSport">الكرة الطائرة</p> : ""}

        {/* {compTeamResult && compTeamResult.length > 0 ? 
          (<>*/}
        {latestMatchesResult.length > 0 && (<>
          <div className="row filterRow">
            <div className="col-xl-4 col-md-4 marginb5">
              <input id="inputDate" value={inputDate} onInput={e => setInputDate(e.target.value)} className="datePicker" placeholder="{t('date')}" type="date" />
            </div>
            <div className="col-xl-4 col-md-4 marginb5">
              <input id="inputDate2" value={inputDate2} onInput={e => setInputDate2(e.target.value)} className="datePicker" placeholder="{t('date')}" type="date" />
            </div>
            <div className="col-xl-4 col-md-4">
              <button onClick={() => filterDate()} className="clearFilter">{t("dateFilter")}</button>
            </div>
          </div>
        </>)}
        {/*  </>) : (<></>)
         } */}

        <div className="matchesList">
          {latestMatchesResult.map((item, index) => (
            <>
              <NavLink className="matchPath2" to={`/competitions/${item.id}`}>
                <div className="matchHolder">
                  <div className="content">
                    {getMatchStatus(item)}
                    {/* <div className="matchStatus live">
                    <p>مباشر</p>
                  </div> */}
                    <div className="topSection">
                      <div className="matchSport">
                        <div className="sport">
                          <img src={item.sportLogo} alt="" />
                          <p>{item.sportName}</p>
                        </div>
                      </div>
                      {/* empty if match is live */}
                      <div className="remainingTime">
                        <p></p>
                        <p>
                          {i18n.language == "ar" ?
                            (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", { locale: arSA, })}</>) :
                            (<>{format(new Date(item?.matchDate), "EEEE dd MMMM yyyy", {})}</>)
                          }
                        </p>
                      </div>
                    </div>
                    <div className="sides">
                      <div className="side side1">
                        <div className="sideInfo">
                          <div className="sideImg yellowBorder">
                            {item.team1Logo == "" ? (
                              <>{getUniversityLogo(item.team1Id)}</>
                            ) : (
                              <>
                                <img src={item.team1Logo} alt="" />
                              </>
                            )}
                          </div>
                          <p>{item.team1Name}</p>
                        </div>
                        <div className="sideScore">
                          <p>{item.team1Score}</p>
                        </div>
                      </div>
                      <div className="side side2">
                        <div className="sideScore">
                          <p>{item.team2Score}</p>
                        </div>
                        <div className="sideInfo">
                          <div className="sideImg greenBorder">
                            {item.team2Logo == "" ? (
                              <>{getUniversityLogo(item.team2Id)}</>
                            ) : (
                              <>
                                <img src={item.team2Logo} alt="" />
                              </>
                            )}
                          </div>
                          <p>{item.team2Name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </>
          ))}

        </div>
      </div>
    </>
  );
};

export default CompMain;
