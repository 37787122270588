import React, { useEffect } from "react";
import "./TermsConditions.scss";
import { images } from "../../../constants";

const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="terms-conditions">
                <div className="content">
                    <div className="data">
                        <img src={images.termsConditions} alt="faq" />
                        <div className="item">
                            <h3>اخلاء المسؤولية (التنازل عن المطالبات)</h3>
                            <p>
                                ما تقدمه البوابة الإلكترونية للإتحاد الرياضي للجامعات السعودية من خدمات ومعلومات ومواد ووظائف، يتم توفيرها لاستخدامكم الشخصي "كما هي" و "كما هي متاحة" دون أي إقرار أو وعود أو ضمانات من أي نوع. ولا يمكننا أن نضمن أو أن نتحمل المسؤولية عن أي انقطاعات أو أخطاء أو تجاوزات قد تنشأ عن استخدام هذه البوابة أو محتوياتها أو أي موقع يرتبط بها -سواء كان ذلك بعلمنا أو بدونه، وليس للمستخدم أي حق في ملكية أو ضمان سرية أي نوع من الاتصالات أو المعلومات التي قام بإرسالها من خلال هذه البوابة.
                                كما أن أي استخدام عام أو تفاعلي داخل البوابة لا يضمن للمستخدم أي حقوق أو تراخيص أو أي امتيازات من أي نوع. وفي حالة تنازل الإتحاد عن أي حق متاح لها محدد ضمن هذه الشروط في أحد الأماكن أو إحدى المناسبات، فإن ذلك لا يعني بأي حال تنازلاً تلقائياً وبشكل دائم عن أية حقوق في أماكن ومناسبات أخرى.
                            </p>
                        </div>
                        <div className="item">
                            <h3> الحماية من الفيروسات </h3>
                            <p>
                                تفحص تقنية المعلومات في الاتحاد الرياضي للجامعات السعودية وتختبر محتويات الخدمات الإلكترونية للإتحاد الرياضي للجامعات السعودية في كل مراحل العمل، وبالتالي يتوجب على المستخدم تشغيل برنامج مضاد للفيروسات بشكل دائم على كل المواد التي يتم تحميلها من الإنترنت، فالبوابة الإلكترونية لا ​تتحمل أي مسؤولية عن أي خسارة أو انقطاع أو تلف لبياناتك أو جهاز الحاسب لديك، والذي قد يحدث أثناء الاتصال بهذه البوابة أو عند استخدام أي مواد من محتوى أو غيره يرد فيها.
                            </p>
                        </div>
                        <div className="item">
                            <h3> إنهاء الاستخدام </h3>
                            <p>
                                يجوز لتقنية المعلومات وحسب تقديرها المطلق إنهاء أو تقييد أو إيقاف حقك في الدخول إلى البوابة، أو استخدام أي من الخدمات المقدمة من خلالها أو جميعها، وذلك دون إشعار ولأي سبب بما في ذلك مخالفة شروط وبنود الاستخدام أو أي سلوك آخر قد تعتبره الإدارة حسب تقديرها الخاص غير قانوني أو مضراً بالآخرين، وفي حالة الإنهاء، فإنه لن يكون مصرحاً لك بالدخول، أو الاستفادة من الخدمات المقدمة أو بعضها.
                            </p>
                        </div>
                        <div className="item">
                            <h3>  شروط عامة  </h3>
                            <p>
                                إن كل المواد والمعلومات والخدمات المتوفرة على البوابة الإلكترونية إرشادية تتعلق بالمجال العلمي وغير هادفة للربح. وإن اللغة العربية هي اللغة الأساسية لاستخدام البوابة والاستفادة من كل المواد المنشورة عليها، كما أن ترجمة أي من هذه المواد لتقديم خدمة مضافة لا يتم الاستناد إليها في تفسير أي خلاف حول ما تتضمنه البوابة من محتوى.
                                دخولك واستخدامك للبوابة الإلكترونية يعني موافقتك على كافة الشروط والأحكام الخاصة باستخدام البوابة، سواء أكنت مستخدماً مسجلاً أم لا، وبدخولك وتصفحك واستخدامك الموقع تكون قد قبلت، من دون قيد أو شرط، أحكام وشروط الاستخدام الواردة في هذه الوثيقة، حيث تخضع البوابة للشروط والأحكام الخاصة بالاستخدام
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsConditions;
