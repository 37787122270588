import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Events.scss";
import { images } from "../../../constants";
import {
  getLinksForms,
  getFile,
  getLatestNews,
  getAds,
  getPublication,
} from "../../../API/susfApi";
import { format } from "date-fns";
import { arSA } from "date-fns/locale";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function Events() {
  const { t } = useTranslation();

  const downloadFile = (attachKey, attachName) => {
    getFile(attachKey, attachName, "publication").then((response) => {
      window.open(response.data?.url);
    });
  };

  const [linksData, setLinksData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [adsData, setAdsData] = useState([]);
  const [pubData, setPubData] = useState([]);
  useEffect(() => {
    getLinksForms().then((data) => {
      setLinksData(data.data);
    });

    getLatestNews("limit=6&moduleNumber=2").then((data) => {
      setEventsData(data.data.latestNews);
    });

    getAds("limit=3").then((data) => {
      setAdsData(data.data);
    });

    getPublication("limit=6").then((data) => {
      // console.log(data.data)
      setPubData(data.data);
    });
  }, []);

  const eventSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  const adSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const printSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="events-section">
        <h2>
          <span>{t("eventsCenter")}</span>
        </h2>
        <div className="row w-100 m-0 row-ltr">
          <div className="right-side col-sm-12 col-md-8">
            <div className="events">
              <div className="heading">
                <p className="label">{t("events")}</p>

                <NavLink to={"/mediaCenter/Activities"}>
                  <p className="pill">
                    {t("all")}
                    <span> {t("events")}</span>
                  </p>
                </NavLink>
              </div>
              <div className="events-slider">
                <Slider {...eventSlider}>
                  {eventsData.map((item, index) => (
                    <div className="event-card__body" key={index}>
                      <div className="event-card__img">
                        <img
                          src={item.image}
                          className="w-100"
                          alt="first image"
                        />
                      </div>
                      <div className="event-card__text">
                        <NavLink to={`/details/${item.id}`}>
                          <h6 className="green  fs-9">{i18n.language == "ar" ? item.title : item.titleEn}</h6>
                        </NavLink>
                        <p className="text-black fs-9  ">
                          {i18n.language == "ar" ?
                            (<>
                              {format(new Date(item.eventDate), "dd MMMM, yyyy - hh:mm a", { locale: arSA })}
                            </>) : (<>
                              {format(new Date(item.eventDate), "dd MMMM, yyyy - hh:mm a", {})}
                            </>)
                          }

                        </p>
                      </div>
                    </div>
                  ))}

                  {/* <div className="event-card__body">
                    <div className="event-card__img">
                      <img src={first} className="w-100" alt="first image" />
                    </div>
                    <div className="event-card__text">
                      <h6 className="green text-end fs-9">
                        تدشين مسابقات الموسم الثاني عشر
                      </h6>
                      <p className="text-black fs-9 text-end ">
                        30 سبتمبر, 2021 - 08:07 م
                      </p>
                    </div>
                  </div>
                  <div className="event-card__body">
                    <div className="event-card__img">
                      <img src={third} className="w-100" alt="second image" />
                    </div>
                    <div className="event-card__text">
                      <h6 className="green text-end fs-9">انطلاق مبادرة نشط</h6>
                      <p className="text-black fs-9 text-end ">
                        30 سبتمبر, 2021 - 08:07 م
                      </p>
                    </div>
                  </div>
                  <div className="event-card__body">
                    <div className="event-card__img">
                      <img src={second} className="w-100" alt="third image" />
                    </div>
                    <div className="event-card__text">
                      <h6 className="green text-end fs-9">
                        تدشين مسابقات الموسم الثاني عشر
                      </h6>
                      <p className="text-black fs-9 text-end ">
                        30 سبتمبر, 2021 - 08:07 م
                      </p>
                    </div>
                  </div>
                  <div className="event-card__body">
                    <div className="event-card__img">
                      <img src={second} className="w-100" alt="third image" />
                    </div>
                    <div className="event-card__text">
                      <h6 className="green text-end fs-9">
                        تدشين مسابقات الموسم الثاني عشر
                      </h6>
                      <p className="text-black fs-9 text-end ">
                        30 سبتمبر, 2021 - 08:07 م
                      </p>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
            <div className="ads">
              <div className="heading">
                <p className="label">{t("ads")}</p>

                <NavLink to={"/mediaCenter/Advertisements"}>
                  <p className="pill">
                    {t("all")}
                    <span>{t("ads")} </span>
                  </p>
                </NavLink>
              </div>
              <div className="ads-slider-container">
                <div className="slider-inner">
                  <Slider {...adSlider}>
                    {adsData.map((item, index) => (
                      <div className="ads-card" key={index}>
                        <p className="hashtag">{i18n.language == "ar" ? item.name : item.nameEn}</p>
                        <div className="ad-content">
                          <a href={item.url} target="_blank">
                            <img
                              src={i18n.language == "ar" ? item.image : item.imageEn}
                              className="w-100"
                              alt="slider image"
                            />
                          </a>
                        </div>
                      </div>
                    ))}

                    {/* <div className="ads-card">
                      <p className="hashtag">#يوم_التطوع_السعودي_والعالمي</p>
                      <div className="ad-content">
                        <img
                          src={fourth}
                          className="w-100"
                          alt="slider image"
                        />
                        <p className="date">30 سبتمبر, 2021 - 08:07 م</p>
                      </div>
                    </div>
                    <div className="ads-card">
                      <p className="hashtag">#يوم_التطوع_السعودي_والعالمي</p>
                      <div className="ad-content">
                        <img
                          src={fourth}
                          className="w-100"
                          alt="slider image"
                        />
                      </div>
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="left-side col-sm-12 col-md-4">
            <div className="prints">
              <div className="heading">
                <p className="label">{t("prints")}</p>

                <NavLink to={"/mediaCenter/Prints"}>
                  <p className="pill">
                    {t("all")}    <span> {t("prints")}</span>
                  </p>
                </NavLink>
              </div>
              <div className="prints-slider-container">
                <div className="cards-holder">
                  <Slider {...printSlider}>
                    {pubData.map((item, index) => (
                      <div className="print-card" key={index}>
                        <div className="whiteCard">
                          <p>{i18n.language == "ar" ? item.name : item.nameEn}</p>
                          <img src={item.image} alt="" />
                        </div>
                        <div className="greenCard">
                          <div className="printContent">
                            <a
                              href="#!"
                              className="downloadPdf"
                              onClick={() =>
                                downloadFile(item.attachKey, item.attachName)
                              }
                            >
                              <img src={images.download} alt="" />
                            </a>
                            {/* <p>30 سبتمبر, 2021 - 08:07 م</p> */}
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* <div className="print-card">
                      <div className="whiteCard">
                        <p>كرة قدم الصالات - طلاب</p>
                        <img src={images.football} alt="" />
                      </div>
                      <div className="greenCard">
                        <div className="content">
                          <img src={images.download} alt="" />
                          <p>30 سبتمبر, 2021 - 08:07 م</p>
                        </div>
                      </div>
                    </div>
                    <div className="print-card">
                      <div className="whiteCard">
                        <p>كرة قدم الصالات - طلاب</p>
                        <img src={images.football} alt="" />
                      </div>
                      <div className="greenCard">
                        <div className="content">
                          <img src={images.download} alt="" />
                          <p>30 سبتمبر, 2021 - 08:07 م</p>
                        </div>
                      </div>
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="models">
              <p className="head">{t("forms")}</p>
              <div className="model-list">
                {linksData.map((item, index) => (
                  <>
                    {item.link ? (
                      <>
                        <a key={index} href={item.link} target="_blank">
                          <p> {i18n.language == "ar" ? item.name : item.nameEn}</p>
                        </a>
                      </>
                    ) : (
                      ""
                    )}

                    {!item.link ? (
                      <>
                        <a
                          key={index}
                          href="#!"
                          onClick={() =>
                            downloadFile(item.attachKey, item.attachName)
                          }
                        >
                          <p> {i18n.language == "ar" ? item.name : item.nameEn}</p>
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
