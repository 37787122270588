import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format, differenceInMinutes } from "date-fns";
import { arSA } from "date-fns/locale";
import { getOneNews } from "../../../API/susfApi";
import "./Details.scss";
import { images } from "../../../constants";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Details = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [content, setNews] = useState([]);
    useEffect(() => {

        getOneNews("id=" + id).then((result) => {
            // console.log(result)
            setNews(result.data);
        });
    }, []);

    return (
        <div className="mediaNews-container">
            {/* <div className="headingImg">
                <img className="headingIcon" src={images.headingIcon} alt="" />
                <div className="overlay"></div>
            </div> */}

            <div className="news-list container">
                <div className="row">

                    {content != undefined ? (
                        <>
                            <div className="content">
                                <div className="main-img">
                                    <img src={content?.image} alt="datails" />
                                </div>
                                <div className="meta-data">

                                    <span>
                                        <img src={images.calendarGreen} alt="calendar" />
                                        {
                                            content?.publishDate ? (
                                                <>
                                                    {
                                                        i18n.language == "ar" ?
                                                            (<>{format(Date.parse(content?.publishDate), "dd MMMM, yyyy", { locale: arSA })}</>) :
                                                            (<>{format(Date.parse(content?.publishDate), "dd MMMM, yyyy", {})}</>)
                                                    }

                                                </>
                                            ) : ''
                                        }
                                    </span>
                                    <span>
                                        <img src={images.calendarGreen} alt="calendar" />
                                        {t("riad")}
                                    </span>


                                </div>
                                <div className="title">
                                    <h3>
                                        {i18n.language == "ar" ? content?.title : content?.titleEn}
                                    </h3>
                                </div>

                                {i18n.language == "ar" || !content.isEnglishApproved ?
                                    (<>
                                        <div className="description" dangerouslySetInnerHTML={{ __html: content?.bodyDecs?.replace(/font-family:[^;']*(;)?/g, "") }}>
                                        </div>
                                    </>) :
                                    (<>
                                        <div className="description" dangerouslySetInnerHTML={{ __html: content?.bodyDecsEn?.replace(/font-family:[^;']*(;)?/g, "") }}>
                                        </div>
                                    </>)
                                }

                                {content?.newsLink ? (
                                    <>
                                        <div className="post-author">
                                            <a href={content?.newsLink} target="_blank" >
                                                {t("newsSource")}
                                            </a>
                                        </div>
                                    </>
                                ) : ''
                                }
                            </div>

                        </>
                    ) : (<></>)
                    }

                </div>
            </div>
        </div>
    );
};

export default Details;
