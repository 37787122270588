import Counters from "./counters/Counters.jsx";
import Services from "./services/Services.jsx";
import Competitions from "./Competitions/Competitions.jsx";
import News from "./News/News.jsx";
import Events from "./Events/Events.jsx";
import Main from "./Main/Main.jsx";
import { useEffect } from "react";

export default function Homepage() {
  useEffect(() => {
    localStorage.setItem("i18nextLng", "ar");
  });

  return (
    <div>
      <Main />
      <Counters />
      <Competitions />
      <News />
      <Events />
      <Services />
    </div>
  );
}
