import { GetApi, PostApi } from "./apiHeaderUtils";

export function getSlider() {
  return GetApi("mobile");
}

export function getLinksForms() {
  return GetApi("mobile/linksform");
}

export function getCompetions() {
  return GetApi("mobile/comp");
}
export function getSportData(queryParam) {
  return GetApi("mobile/sportdata", queryParam);
}

export function getSportResult(queryParam) {
  return GetApi("mobile/sportresult", queryParam);
}

export function getFile(id, name, path) {
  return GetApi("file/fileView/" + id + "?p=" + path);
}

export function getShared() {
  return GetApi("mobile/shared");
}

export function getLatestMatchesResult(queryParam) {
  return GetApi("mobile/latestsportresult", queryParam);
}

export function getAds(queryParam) {
  return GetApi("mobile/ads", queryParam);
}


export function getPublication(queryParam) {
  return GetApi("mobile/publication", queryParam);
}

export function getAllPublication() {
  return GetApi("mobile/allpublication");
}

export function getLatestNews(queryParam) {
  return GetApi("mobile/news", queryParam);
}

export function getOneNews(queryParam) {
  return GetApi("mobile/onearticle", queryParam);
}

export function getMediaCenter(queryParam) {
  return GetApi("mobile/mediacenter", queryParam);
}

export function getSessons(queryParam) {
  return GetApi("mobile/sessons", queryParam);
}

export function getLaw() {
  return GetApi("mobile/law");
}

export function getCompetionResult(queryParam) {
  return GetApi("mobile/sportresultne", queryParam);
}

export function getMatchData(queryParam) {
  return GetApi("mobile/matchData", queryParam);
}


export function postTicket(body) {
  return PostApi("mobile/support", body);
}

export function getImage(queryParam) {
  return GetApi("file/simg/"+ queryParam);
}