import React, { useEffect, useState } from "react";

import { getAds } from "../../../API/susfApi";
import "./Advertisements.scss";
import { images } from "../../../constants";
import i18n from "i18next";

const Advertisements = () => {
    const [adsData, setNews] = useState([]);
    useEffect(() => {
        getAds("limit=12").then((result) => {
            setNews(result.data);
        });
    }, []);

    return (
        <div className="ads-container">
            <div className="headingImg">
                <img className="headingIcon" src={images.headingIcon} alt="" />
                <div className="overlay"></div>
            </div>

            <div className="ads-list container">
                <div className="row ads-row">

                    {adsData && adsData.length > 0 ? (
                        <> 
                            {adsData.map((advertice) => (
                                <div className="col-sm-12 col-md-6 col-lg-4" key={advertice._id}>
                                    <div className="advertice-box">
                                        <a href={advertice.url} target="_blank">
                                            <img src={images.extlink} alt="link" />
                                        </a>
                                        <img
                                            src={i18n.language == "ar" ? advertice.image : advertice.imageEN}
                                            alt="main-img"
                                            className="main-img"
                                        />
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : ''
                    }
                </div>
            </div>
        </div>
    );
};

export default Advertisements;
