import React, { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import "./MatchDetails.scss";
import { images } from "../../../constants";
import { getMatchData } from "../../../API/susfApi";
import { useTranslation } from "react-i18next";

const MatchDetails = () => {
  const { t } = useTranslation();

  const [
    latestMatchesResult,
    latestMatchesResultAll,
    latestMatch,
    setMatchesData,
    setMatchesResultData,
    setMatchesResultDataAll,
    setMainSeassonsData,
    setCurrentSeassonName,
    setAllSeassonNameList,
    setCurrentSeassonCompet,
    setCurrentSeassonCompetAll,
    setSeassonTeamResult,
    seassonTeamResult,
    setCompTeamResult,
    compTeamResult,
    compUniverData,
    compIndivScore,
    setCompIndivScore,
    compIndivFlag,
    setCompIndivFlag,
    isMatchDetails, setMatchDetails,
    allOrderData, setAllOrderData,
    setSelectedComp,
    setIsNotDetails
  ] = useOutletContext();

  // const [
  //   setCurrentSeassonName,
  //   //isMatchDetails, setMatchDetails
  // ] = useOutletContext();

  const { id } = useParams();

  const [compName, setSelectedCompName] = useState("");
  const [compImage, setSelectedCompImage] = useState("");
  const [content, setNews] = useState([]);

  ////////////////////////////////////////
  ////  Team One
  const [allGoalsPenData, setAllGoPen] = useState([]);
  const [goalsData, setGoals] = useState([]);

  const [team1Name, setteam1Name] = useState("");
  const [team1Image, setteam1Image] = useState("");
  const [team1Score, setteam1Score] = useState("");
  const [team1Players, setteam1Players] = useState([]);

  const [team2Name, setteam2Name] = useState("");
  const [team2Image, setteam2Image] = useState("");
  const [team2Score, setteam2Score] = useState("");
  const [team2Players, setteam2Players] = useState([]);

  const [compType, setCompType] = useState(1);

  ////////////////////////////////////////
  useEffect(() => {
    // console.clear();
    // console.log('ddddddddddddddddddddddddddddddddddddddddd');

    // setCurrentSeassonName("sssssssssssssssss");
    setSelectedComp("");
    setIsNotDetails(false);

    getMatchData("id=" + id).then((result) => {

      if (result.data && result.data.matchTeams && result.data.matchTeams.length > 0) {
        setAllGoPen(result.data.allPenGoa);
        setGoals(result.data.goalsData);
        setSelectedCompName(result.data.matchTeams[0].competionName);
        setSelectedCompImage(result.data.matchTeams[0].competionImage);

        let team1 = result.data.matchTeams[0];
        setteam1Name(team1.universityName);
        setteam1Image(team1.universityImage);
        setteam1Score(result.data.team1Score);
        setteam1Players(team1.team);
        setCompType(team1.competionType);
console.log(team1.competionType)
        if (result.data.matchTeams.length == 2) {
          let team2 = result.data.matchTeams[1];
          setteam2Name(team2.universityName);
          setteam2Image(team2.universityImage);
          setteam2Score(result.data.team2Score);
          setteam2Players(team2.team);
        }
      }

      // console.clear();
      // console.log(result.data)
      // setNews(result.data);
    });
  }, []);


  return (
    <>
      {compName && (


        <div className="matchDetails">
          <div className="matchSport">
            <div className="sport">
              <img src={compImage} alt="" />
              <p>{compName}</p>
            </div>
          </div>
          <div className="teams">
            <div className="innerBox">
              <div className="team_container ">
                <div className="teamHeading team1">
                  <div className="teamInfo">
                    <p>{team1Name}</p>
                    <div className="teamImg yellowBorder">
                      <img src={team1Image} alt="" />
                    </div>
                  </div>
                  <div className="sideScore">
                    <p>{team1Score}</p>
                  </div>
                </div>
                <div className="teamList">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="playerName">{t("playername")}</th>
                        <th>{t("playernumber")}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {team1Players.length > 0 ? (<>
                        {team1Players.map((item, index) => (
                          <>

                            <tr>
                              <td>
                                {compType == "1" ? (<>
                                  <img src={`data:image/jpeg;base64,${item.image}`} alt="" />
                                </>) : (<>
                                  <img src={images.girl} alt="" />
                                </>)}
                                
                              </td>
                              <td className="playerName">{item.Name}</td>
                              <td>{index + 1}</td>
                            </tr>
                          </>
                        ))}

                      </>) : (<></>)}

                      {/* <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="team_container ">
                <div className="teamHeading team1">
                  <div className="sideScore">
                    <p>{team2Score}</p>
                  </div>
                  <div className="teamInfo">
                    <div className="teamImg greenBorder">
                      <img src={team2Image} alt="" />
                    </div>
                    <p>{team2Name}</p>
                  </div>
                </div>
                <div className="teamList">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="playerName">{t("playername")}</th>
                        <th>{t("playernumber")}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {team2Players.length > 0 ? (<>
                        {team2Players.map((item, index) => (
                          <>

                            <tr>
                              <td>
                              
                              {compType == "1" ? (<>
                                  <img src={`data:image/jpeg;base64,${item.image}`} alt="" />
                                </>) : (<>
                                  <img src={images.girl} alt="" />
                                </>)}
                              </td>
                              <td className="playerName">{item.Name}</td>
                              <td>{index + 1}</td>
                            </tr>
                          </>
                        ))}

                      </>) : (<></>)}
                      {/* 
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={images.playerImg} alt="" />
                        </td>
                        <td className="playerName">ريان عبدالله</td>
                        <td>1</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p className="eventsLabel">
            <img src={images.matchEvents} alt="" />
            <span>{t("matchevents")}</span>
          </p>

          <div className="matchEvents">
            <div className="events_rightSide">

              {allGoalsPenData.length > 0 && (
                <>
                  {allGoalsPenData.map((item, index) => (
                    <>
                      <div className="eventCard">
                        <div className="eventCard_top">
                          <div className="player">
                          {compType == "1" ? (<>
                                  <img src={`data:image/jpeg;base64,${item.playerImage}`} alt="" />
                                </>) : (<>
                                  <img src={images.girl} alt="" />
                                </>)}
                            <p>{item.playerName}</p>
                          </div>
                          <div className="team">
                            <p>{item.universityName}</p>
                            <div className={item.isTeam1 ? "teamImg  yellowBorder" : "teamImg  greenBorder"}>
                              <img src={item.universityLogo} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="eventCard_bottom">
                          <div className="time">
                            <p>{t("minute")} {item.dateNumber} </p>
                            <img src={images.time} alt="" />
                          </div>
                          <div className="type">
                            {item.isPenality ? (<>
                              <p>{item.penalityName} </p>
                              <div className="teamImg">
                                <img src={item.penalityImage} alt="" />
                              </div>
                            </>) : (<>
                              {!item.isPenality && item.isGoalType == 1 ? (<>
                                <p>{t("goal")}</p>
                                <div className="teamImg">
                                  <img src={images.volleyball} alt="" />
                                </div>
                              </>) : (<>

                                {!item.isPenality && item.isGoalType == 2 && item.isGoal ? (<>
                                  <p>{t("scoredpenality")}</p>
                                  <div className="teamImg">
                                    <img src={images.volleyball} alt="" />
                                  </div>
                                </>) : (<>
                                  <p>{t("penalty")}</p>
                                  <div className="teamImg">
                                    <img src={images.volleyball} alt="" />
                                  </div>
                                </>)}
                              </>)}

                            </>)}
                            {/* <p>ضربة جزاء </p> */}

                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}

              {/* <div className="eventCard">
                <div className="eventCard_top">
                  <div className="player">
                    <img src={images.playerImg} alt="" />
                    <p>ريان عبدالله</p>
                  </div>
                  <div className="team">
                    <p>جامعة الإمام محمد بن سعود الإسلامية</p>
                    <div className="teamImg yellowBorder">
                      <img src={images.mohamedBenSo3od} alt="" />
                    </div>
                  </div>
                </div>
                <div className="eventCard_bottom">
                  <div className="time">
                    <p>فى الدقيقه 25 </p>
                    <img src={images.time} alt="" />
                  </div>
                  <div className="type">
                    <p>ضربة جزاء </p>
                    <div className="teamImg">
                      <img src={images.volleyball} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="eventCard">
                <div className="eventCard_top">
                  <div className="player">
                    <img src={images.playerImg} alt="" />
                    <p>ريان عبدالله</p>
                  </div>
                  <div className="team">
                    <p>جامعة الإمام محمد بن سعود الإسلامية</p>
                    <div className="teamImg yellowBorder">
                      <img src={images.mohamedBenSo3od} alt="" />
                    </div>
                  </div>
                </div>
                <div className="eventCard_bottom">
                  <div className="time">
                    <p>فى الدقيقه 25 </p>
                    <img src={images.time} alt="" />
                  </div>
                  <div className="type">
                    <p>كارت اصفر</p>
                    <div className="teamImg">
                      <img src={images.yellowCard} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="eventCard">
                <div className="eventCard_top">
                  <div className="player">
                    <img src={images.playerImg} alt="" />
                    <p>ريان عبدالله</p>
                  </div>
                  <div className="team">
                    <p>جامعة الإمام محمد بن سعود الإسلامية</p>
                    <div className="teamImg yellowBorder">
                      <img src={images.mohamedBenSo3od} alt="" />
                    </div>
                  </div>
                </div>
                <div className="eventCard_bottom">
                  <div className="time">
                    <p>فى الدقيقه 25 </p>
                    <img src={images.time} alt="" />
                  </div>
                  <div className="type">
                    <p>كارت احمر</p>
                    <div className="teamImg">
                      <img src={images.redCard} alt="" />
                    </div>
                  </div>
                </div>
              </div> */}


            </div>
            <div className="events_leftSide">
              <div className="timeline">
                <div className="split">
                  <div className="splitIcon">
                    <img src={images.timeWhite} alt="" />
                  </div>
                </div>
                {/* minute container contains 2 sides of the minute events, step_right and step_left apply desired styles */}

                {goalsData.length > 0 && (
                  <>
                    {goalsData.map((item, index) => (
                      <>
                        {item.isTeam1 ? (
                          <>
                            <div className="minute_container">
                              <div className="step active step_right">
                                <div className="event">


                                  {item.goalType == 1 ? (<>
                                    <p className="eventLabel">{t("goal")}</p>
                                  </>) : (<>

                                    {item.goalType == 2 && item.isGoal ? (<>
                                      <p className="eventLabel">{t("scoredpenality")}</p>
                                    </>) : (<>
                                      <p className="eventLabel">{t("penalty")}</p>
                                    </>)}
                                  </>)}


                                  <img src={images.penalty} alt="" />
                                  <p className="name">{item.playerName}</p>
                                </div>
                                <p className="minute">{item.goalTime}</p>
                              </div>
                              <div className="step step_left"></div>
                            </div>
                          </>) : (<>

                            <div className="minute_container">
                              <div className="step step_right"></div>
                              <div className="step active step_left">
                                <div className="event">
                                  {item.goalType == 1 ? (<>
                                    <p className="eventLabel">{t("goal")}</p>
                                  </>) : (<>

                                    {item.goalType == 2 && item.isGoal ? (<>
                                      <p className="eventLabel">{t("scoredpenality")}</p>
                                    </>) : (<>
                                      <p className="eventLabel">{t("penalty")}</p>
                                    </>)}
                                  </>)}
                                  <img src={images.penalty} alt="" />
                                  <p className="name">{item.playerName}</p>
                                </div>
                                <p className="minute">{item.goalTime}</p>
                              </div>
                            </div>
                          </>)}


                      </>
                    ))}
                  </>
                )}
                {/* <div className="minute_container">
                  <div className="step active step_right">
                    <div className="event">
                      <p className="eventLabel">ضربة جزاء مسجلة</p>
                      <img src={images.penalty} alt="" />
                      <p className="name">محمد عبدالعزيز</p>
                    </div>
                    <p className="minute">22</p>
                  </div>
                  <div className="step step_left"></div>
                </div>
                <div className="minute_container">
                  <div className="step step_right"></div>
                  <div className="step active step_left">
                    <div className="event">
                      <p className="eventLabel">ضربة جزاء مسجلة</p>
                      <img src={images.penalty} alt="" />
                      <p className="name">محمد عبدالعزيز</p>
                    </div>
                    <p className="minute">22</p>
                  </div>
                </div>
                <div className="minute_container">
                  <div className="step step_right"></div>
                  <div className="step active step_left">
                    <div className="event">
                      <p className="eventLabel">ضربة جزاء مسجلة</p>
                      <img src={images.penalty} alt="" />
                      <p className="name">محمد عبدالعزيز</p>
                    </div>
                    <p className="minute">22</p>
                  </div>
                </div>
                <div className="minute_container">
                  <div className="step active step_right">
                    <div className="event">
                      <p className="eventLabel">ضربة جزاء مسجلة</p>
                      <img src={images.penalty} alt="" />
                      <p className="name">محمد عبدالعزيز</p>
                    </div>
                    <p className="minute">22</p>
                  </div>
                  <div className="step step_left"></div>
                </div>
                <div className="minute_container">
                  <div className="step step_right"></div>
                  <div className="step active step_left">
                    <div className="event">
                      <p className="eventLabel">ضربة جزاء مسجلة</p>
                      <img src={images.penalty} alt="" />
                      <p className="name">محمد عبدالعزيز</p>
                    </div>
                    <p className="minute">22</p>
                  </div>
                </div>
                 */}
              </div>
            </div>
          </div>
        </div>

      )}

      {!compName && (<>
        <div className="emptyData">
          <img src="/content/images/nodata.png" alt="no data" />
          <br />
          <span>{t("noDataFound")}</span>
        </div>
      </>)}
    </>
  );
};

export default MatchDetails;
