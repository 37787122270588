import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { images } from "../../../../../constants";
import "./slider.scss";
import { getLatestNews } from "../../../../../API/susfApi";
import { NavLink } from "react-router-dom";
import i18n from "i18next";

export default function SliderNews() {
  let sliderRef = useRef(null);
  const [news, setNews] = useState([]);
  useEffect(() => {
    getLatestNews("limit=6&moduleNumber=1").then((result) => {
      var latesNews = result.data.latestNews.map((x) => {
        return {
          id: x.id,
          title: x.title,
          titleEn: x.titleEn,
          description: x.bodyDecs.replace(/<img .*?>/g, ""), //.length > 220 ? x.bodyDecs.substring(0, 220) : x.bodyDecs,
          descriptionEn: x.bodyDecsEn ? x.bodyDecsEn.replace(/<img .*?>/g, "") :"",
          image: x.image,
          isEnglishApproved:x.isEnglishApproved ? x.isEnglishApproved : false
        };
      });
      setNews(latesNews);
    });
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="slider-container-internal">
      <div className="slider-nav">
        <div
          className="nav-arrow"
          onClick={() => sliderRef.current.slickNext()}
        >
          <img src={images.arrowRight} alt="" />
        </div>
        <div
          className="nav-arrow"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <img src={images.arrowLeft} alt="" />
        </div>
      </div>
      <div className="slider-holder">
        <Slider {...settings} ref={sliderRef}>
          {news.map((newsItem, index) => (
            <div key={index} className="slide-body">
              <div className="body-img">
                <img src={newsItem.image}></img>
              </div>
              <div className="body-text">
                <NavLink to={`/details/${newsItem.id}`}>
                  <p className="slide-title">{i18n.language == "ar" ? newsItem.title : newsItem.titleEn}</p>
                </NavLink>
                <div className="slide-content">
                  
                  {i18n.language == "ar" || !newsItem.isEnglishApproved ?
                   (<> 
                   <div
                    dangerouslySetInnerHTML={{ __html: newsItem.description }}
                  ></div>
                  </>):
                  (<>
                  
                    <div
                    dangerouslySetInnerHTML={{ __html: newsItem.descriptionEn }}
                  ></div>

                  </>)
                  }
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
