export const en = {
  nav: {
    main: "Home",
    ethad: {
      main: "Sports Federation",
      about: "About Us",
      chef: "Federation Chief",
      structure: "Administrative structure",
      law: "Regulations & Laws"
    },
    competion: "Competitions",
    otherServices: {
      main: "Electronic services",
      nashet: "Nashet portal",
      ahlia: "Eligibility portal",
      result: "Teams and results portal",
      competition: "Competitions portal",
      tasks: "Task management portal",
      email: "E-mail"
    },
    news: {
      main: "Media",
      news: "News",
      events: "Events Center",
      vidios: "Video Gallery",
      images: "Photo Gallery",
      ads: "Advertisements",
      media: "Media files",
      prints: "Publications Center"
    }
  },
  latestMatches: "Latest Matches",
  moasher: "Universities sports indicator",
  viewMore: "View More",
  male: "Male",
  female: "Female",
  VS: "VS",

  season: "Season",
  university: "Universities",
  competitions: "Competitions",
  internationalComp: "International competitions",
  theMale: "Males",
  theFemale: "Femals",

  latestComp: "Latest competitions",
  eventsCenter: "Media Center",

  forms: "Questionnaires & forms",
  all: "All",
  prints: "Publications",
  ads: "Advertisements",
  events: "Events",

  latestNews: "Latest News",

  date: "Date",
  dateFilter: "Filter by date",
  orderTable: "Ranking table",
  timeLine: "Timeline",
  latestResult: "Latest results",
  nextMatch: "Next match",
  matches: "Matches",
  team: "Team",
  points: "Points",
  raw: "Draw",
  lose: "Loss",
  win: "winning",
  play: "Play",

  nextLbl:"Coming",
  finishedLbl:"Completed",
  cancelLbl:"Canceled",
  postponedLbl:"Postponed",
  clearSearch : "Clear Search",
  gatherings:"Gatherings",
  from:"From",
  to:"To",
  particiUniversity:"Participating universities",
  riad:"Riyadh",
  playername :"Player name",
  playernumber:"Player number",
  matchevents:"Match events",
  minute:"In the minute",
  goal:"Goal",
  scoredpenality:"Penalty kick scored",
  penalty:"Penalty",
  noDataFound: "No Data",
  moshahada:"View",
  newsSource : "Source",
  footer: {
    contactwithus: "Follow us on social media accounts",
    contactinfo: "Contact Information",
    address: "Al-Takhasosi Street - Al-Ma'athar Al-Shamaliya - Riyadh 12712",
    usfulLinks: "Useful Links",
    eduministry: "Ministry of education",
    arabicnatian: "The Arab Federation for University Sports",
    internationalnatian: "International University Sports Federation",
    termscondtions: "Terms and Conditions",
    support: "Support",
    copyrights:"All rights reserved to the Ministry of Education - Higher Education - Kingdom of Saudi Arabia"
  }
};  

