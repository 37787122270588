import React, { useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AllRoutes from "./routes";

import { Header, Nav, Footer } from "./Shared";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [toggleMobileNav, setToggleMobileNav] = useState(false);

  return (
    <BrowserRouter basename="/">
      <Header setToggleMobileNav={setToggleMobileNav} />
      <Nav
        toggleMobileNav={toggleMobileNav}
        setToggleMobileNav={setToggleMobileNav}
      />
      <AllRoutes></AllRoutes>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
