import { ar as home } from "./locales/ar/home";

const ar = {
  translation: {
    ...home,

    // Add more translations for arabic
  },
};

export default ar;
