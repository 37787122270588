export const ar = {
  nav: {
    main: "الرئيسية",
    ethad: {
      main: "الاتحاد الرياضي",
      about: "عن الاتحاد",
      chef: "رئيس الاتحاد",
      structure: "الهيكل الإداري",
      law: "اللوائح والقوانين"
    },
    competion: "المسابقات",
    otherServices: {
      main: "الخدمات الإلكترونية",
      nashet: "بوابة نشط",
      ahlia: "بوابة الأهلية",
      result: "بوابة المنتخبات والنتائج",
      competition: "بوابة المسابقات",
      tasks: "نظام إدارة المهام",
      email: "البريد الإلكتروني"
    },
    news: {
      main: "المركز الإعلامي",
      news: "الأخبار",
      events: "الفعاليات",
      vidios: "الوسائط",
      images: "الصور",
      ads: "الإعلانات",
      media: "الملف الإعلامي",
      prints: "المطبوعات"
    }
  },
  latestMatches: "أحدث المباريات",
  moasher: "مؤشر الرياضة الجامعية",
  viewMore: "عرض المزيد",
  male: "طلاب",
  female: "طالبات",
  VS: "VS",

  season: "الموسم",
  university: "الجامعات",
  competitions: "المسابقات",
  internationalComp: "المسابقات الدولية",
  theMale: "الطلاب",
  theFemale: "الطالبات",

  latestComp: "أحدث المسابقات",
  eventsCenter: "المركز الإعلامي",

  forms: "استبيانات ونماذج",
  all: "جميع ",
  prints: "المطبوعات",
  ads: "الإعلانات",
  events: "الفعاليات",

  latestNews: "آخر الأخبار",

  date: "التاريخ",
  dateFilter: "تصفية بالتاريخ",
  orderTable: "جدول الترتيب",
  timeLine: "الخط الزمني",
  latestResult: "أحدث النتائج",
  nextMatch: "المباراة القادمة",
  matches: "المباريات",
  team: "الفريق",
  points: "النقاط",
  raw: "تعادل",
  lose: "خسارة",
  win: "فوز",
  play: "لعب",

  nextLbl: "القادمة",
  finishedLbl: "تم الانتهاء",
  cancelLbl: "ألغيت",
  postponedLbl: "مؤجلة",
  clearSearch: "مسح الفرز",
  gatherings: "التجمعات",
  from: "من",
  to: "الي",
  particiUniversity: "الجامعات المشاركة",
  riad: "الرياض",
  playername: "اسم لاعب",
  playernumber: "رقم لاعب",
  matchevents: "أحداث المباراة",
  minute: "فى الدقيقه",
  goal: "هدف",
  scoredpenality: "ضربة جزاء مسجلة",
  penalty: "ضربة جزاء",
  noDataFound: "لا يوجد",
  moshahada: "مشاهدة",
  newsSource : "مصدر الخبر",
  footer: {
    contactwithus: "تابعنا على حسابات التواصل الإجتماعي",
    contactinfo: "معلومات التواصل",
    address: "شارع التخصصي - المعذر الشمالي - الرياض 12712",
    usfulLinks: "روابط مفيدة",
    eduministry: "وزارة التعليم",
    arabicnatian: "الاتحاد العربي للرياضة الجامعية",
    internationalnatian: "الاتحاد الدولي للرياضة الجامعية",
    termscondtions: "الشروط والأحكام",
    support: "الدعم الفني",
    copyrights: "جميع الحقوق محفوظة لـوزارة التعليم - التعليم العالي - المملكة العربية السعودية"
  }
};  
