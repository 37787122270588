import React, { useEffect, useState } from "react";

import { getMediaCenter } from "../../../API/susfApi";
import "./Videos.scss";
import { images } from "../../../constants";
import ModalVideo from "react-modal-video";

const Videos = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    getMediaCenter("limit=12").then((result) => {
      setNews(result.data.vidios);
    });
  }, []);

  const [isOpen, setisOpen] = useState(false);
  const openModal = () => {
    setisOpen(!isOpen);
  };

  const [videoID, setVideoID] = useState("");

  const playVideo = (id) => {
    setVideoID(id);
    setisOpen(!isOpen);
  };

  return (
    <>
      <div className="mediaNews-container videos">
        <div className="headingImg">
          <img className="headingIcon" src={images.headingIcon} alt="" />
          <div className="overlay"></div>
        </div>

        <div className="videos-list container">
          <div className="row videos-row">
            {news.map((video, index) => (
              <>
                <div
                  className="col-lg-4 col-md-6 col-sm-12 news-card"
                  index={index}
                >
                  <div
                    className="media-box"
                    onClick={() => playVideo(video.url)}
                  >
                    <img
                      src={video.image}
                      alt="main-img"
                      className="main-img"
                    />
                    <img
                      src={images.playOutline}
                      alt="play"
                      className="play-img"
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoID}
        onClose={openModal}
      />
    </>
  );
};

export default Videos;
