import React, { useEffect, useState } from "react";
import { getLaw, getFile } from "../../../API/susfApi";
import "./Law.scss";
import { images } from "../../../constants";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Law = () => {
    const { t } = useTranslation();

    const [laws, setLaw] = useState([]);
    useEffect(() => {
        getLaw().then((result) => {
            setLaw(result.data);
        });
    }, []);

    const viewDoc = (attachKey, attachName) => {
        getFile(attachKey, attachName, "publication").then((response) => {
            window.open(response.data?.url, "_self");
        });
    }

    return (
        <div className="mediaNews-container laws">
            <div className="headingImg">
                <img className="headingIcon" src={images.headingIcon} alt="" />
                <div className="overlay"></div>
            </div>

            <div className="news-list container">
                <div className="row">

                    {laws.map((elem, index) => (
                        <>
                            <div className="col-sm-12 col-md-12 col-lg-6" key={index}>
                                <div className="laws-box">
                                    <div className="info">
                                        <h3>
                                            {i18n.language == "ar" ? elem.name : elem.nameEn}
                                        </h3>
                                        {/* <h4>
                                {elem.description}
                              </h4> */}
                                        <button className="btn" onClick={() => viewDoc(elem.attachKey, elem.attachName)}>
                                            {t("moshahada")}
                                        </button>
                                    </div>
                                    <div className="img">
                                        <img src={elem.image} alt="laws" />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-4 col-md-6 col-sm-12 news-card" index={index}>
                  <div className="card-img">
                    <img src={newsItem.image} alt="" />
                  </div>
                  <div className="card-body">
                    <p className="news-date">
                      <span>{format(new Date(newsItem.publishDate), "dd MMMM , yyyy", {locale: arSA,})}</span>
                      <img src={images.calendar} alt="" />
                    </p>
                    <p className="news-text">
                    {newsItem.title}
                    </p>
                    <hr />
                    <p className="news-location">الرياض</p>
                  </div>
                </div> */}
                        </>
                    ))}




                </div>
            </div>
        </div>
    );
}

export default Law;