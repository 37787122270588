import React, { useEffect, useState } from "react";

import { getMediaCenter } from "../../../API/susfApi";
import "./Photos.scss";
import { images } from "../../../constants";
import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

const Photos = () => {
    const [imagesData, setNews] = useState([]);
    useEffect(() => {
        getMediaCenter("limit=12").then((result) => {
            setNews(result.data.images);
        });
    }, []);


    return (
        <div className="mediaNews-container photos">
            <div className="headingImg">
                <img className="headingIcon" src={images.headingIcon} alt="" />
                <div className="overlay"></div>
            </div>

            <div className="news-list container">
                <div className="row">
                    <div class="grid">
                        {imagesData && imagesData.length > 0 ? (
                            <>
                                <Gallery>
                                    {imagesData.map(photo => (
                                        <Item
                                            original={photo.image}
                                            thumbnail={photo.image}
                                            width="800"
                                            height="600"
                                        >
                                            {({ ref, open }) => (
                                                <img ref={ref} onClick={open} src={photo.image} />
                                            )}
                                        </Item>
                                    ))}
                                </Gallery>
                            </>
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Photos;
