import React, { useEffect, useState } from "react";
import { format, differenceInMinutes } from "date-fns";
import { arSA } from "date-fns/locale";
import { getMediaCenter } from "../../../API/susfApi";
import "./MediaFiles.scss";
import { images } from "../../../constants";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const MediaFiles = () => {
  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  useEffect(() => {
    getMediaCenter("limit=12").then((result) => {
      console.clear();
      console.log(result)
      var latesNews = result.data.newspaper.map((x) => {
        return {
          id: x.id,
          title: x.title,
          titleEn: x.titleEn,
          publishDate: x.publishDate,
          image: x.image,
          newsLink: x.newsLink
        };
      });
      setNews(latesNews);
    });
  }, []);


  return (
    <div className="mediaFiles-container">
      <div className="headingImg">
        <img className="headingIcon" src={images.headingIcon} alt="" />
        <div className="overlay"></div>
      </div>
      <div className="mediaList container">
        <div className="row media-row">

          {news.map((newsItem, index) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12" index={index}>
                <div className="media-card">
                  <div className="card-datePill">
                    <p>
                      <img src={images.calendarWhite} alt="" />
                      {i18n.language == "ar" ?
                        (<> 
                         <span>{format(new Date(newsItem.publishDate), "dd MMMM , yyyy", { locale: arSA, })}</span>
                        </>)
                        : (
                          <>
                          <span>{format(new Date(newsItem.publishDate), "dd MMMM , yyyy", {  })}</span>
                          </>)
                      }
                    </p>
                  </div>
                  <div className="card-body">
                    <p className="card-label">
                      {i18n.language == "ar" ? newsItem.title : newsItem.titleEn}
                    </p>
                    <div className="card-img">
                      <img src={newsItem.image} alt="" />
                    </div>
                    <div className="cardBtn">
                      <NavLink to={`/details/${newsItem.id}`} >
                        <button className="btn">
                          {t("viewMore")}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            />
                          </svg>
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaFiles;
