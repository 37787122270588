import "./About.scss";
import { images } from "../../../constants";

const About = () => {
  return (
    <div className="About-container">
      <div className="vision">
        <div className="innerContent">
          <div className="rightSide">
            <div className="imgHolder">
              <img src={images.vision} alt="" />
            </div>
          </div>
          <div className="leftSide">
            <h2>الرؤية</h2>
            <hr />
            <p>
              تحقيق التميز والريادة للرياضة في الجامعات السعودية للوصول إلى
              مستويات عالمية متقدمة بما يتناسب مع قيم الجامعات ومبادئها وأهدافها
              .
            </p>
          </div>
        </div>
      </div>
      <div className="message">
        <div className="innerContent">
          <div className="rightSide">
            <h2>الرسالة</h2>
            <hr />
            <p>
              رعاية الحركة الرياضية الجامعية ودعمها وتطويرها ورفع مستواها من
              خلال مجتمع رياضــــــــــــي يشجع على تطبيق الروح الرياضية
              ويؤكـــــــــــــــد علــى تعزيز ممارســة النشاط الرياضي
              ونشــــــــــره بين طلاب الجامعات وتحديد القواعد ورسم السياسات
              المنظمــــــــة للأنشطة وتمثيل وتنسيق المواقف والآراء للجامعات
              السعـــــــــودية في المشاركات الرياضية المحلية والإقليمية
              والدولية.
            </p>
          </div>
          <div className="leftSide">
            <div className="imgHolder">
              <img src={images.aboutMessage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="goals">
        <div className="innerContent">
          <div className="sides">
            <div className="rightSide">
              <div className="imgHolder">
                <img src={images.goals} alt="" />
              </div>
            </div>
            <div className="leftSide">
              <h2>الأهداف</h2>
              <hr />
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                تنظيم أنشطة ولقاءات رياضية عالية الجودة لطلاب الجامعات من خلال
                تبني إطار تنظيمي مميز والتعاون المشترك مع الاتحادات الرياضية
                الوطنية.
              </p>
            </div>
          </div>
          <div className="wideSection">
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>

              <span>
                الإشراف على الأنشطة الرياضية بين المؤسسات الواقعة تحت إشراف
                وزارة التعليم.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                رعاية الحركة الرياضية الجامعية السعودية و دعمها و تطويرها.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                تمثيل الجامعات السعودية فى المشاركات الرياضية المحلية و
                الإقليمية و الدولية.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                تنسيق المواقف و الآراء في المشاركات الرياضية الجامعية المحلية و
                الإقليمية و الدولية.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                الارتقاء بالرياضة الجامعية من مجرد نشاط طلابي غير صفي إلى برامج
                منهجيه تؤكد على أهمية المشاركة في الأنشطة الرياضية كأحد عناصر
                النمو المتكامل لإعداد طلاب الجامعة للحياة المهنية.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                تطوير البرامج الرياضية التربوية من خلال تبني مجموعة من المعايير
                الأخلاقية بما يتناسب مع القضايا التربوية الرياضية المعاصرة.
              </span>
            </p>
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </span>
              <span>
                دعم الدراسات والبحوث العلمية التي تؤدي إلى التوافق والانسجام
                لتحقيق التميز الأكاديمي والرياضي وبما يعزز التواصل بين الجامعات.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
