import { Routes, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  HomePage,
  Competitions,
  AboutEtihad,
  ManagerialStructure,
  EtihadLeader,
  Activities,
  News,
  MediaFiles,
  Law,
  Videos,
  Photos,
  Prints,
  Advertisements,
  CompMain,
  MatchDetails,
  Details,
  TermsConditions,
  Support,
} from "../Pages";

const AllRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/competitions" element={<Competitions />}>
      <Route path="" element={<CompMain />} />
      <Route path="" element={<CompMain />} />
      <Route path=":id" element={<MatchDetails />} />
      {/* matchDetails/       :sport*/}
    </Route>
    <Route path="Etihad/AboutEtihad" element={<AboutEtihad />} />
    <Route
      path="Etihad/ManagerialStructure"
      element={<ManagerialStructure />}
    />
    <Route path="etihad/Leader" element={<EtihadLeader />} />
    <Route path="etihad/Law" element={<Law />} />
    <Route path="mediaCenter/News" element={<News />} />
    <Route path="mediaCenter/Activities" element={<Activities />} />
    <Route path="mediaCenter/Videos" element={<Videos />} />
    <Route path="mediaCenter/Photos" element={<Photos />} />
    <Route path="mediaCenter/Advertisements" element={<Advertisements />} />
    <Route path="mediaCenter/MediaFiles" element={<MediaFiles />} />
    <Route path="mediaCenter/Prints" element={<Prints />} />
    <Route path="/Details/:id" element={<Details />} />
    <Route path="/TermsConditions" element={<TermsConditions />} />
    <Route path="/Support" element={<Support />} />
  </Routes>
);

export default AllRoutes;
